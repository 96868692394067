import { useAuth } from "context/AuthContext";
import "./styles.scss";
import { useDialog } from "context/DialogContext";
import { useEvent, useMount } from "react-use";
import { useNavigate } from "react-router-dom";
import AvatarCharacter from "components/avatar-character";
import RegionThumbnail from "components/region-thumbnail";
import AudioStep11 from "./audio";
import { useAppStore } from "store";

import gadget from "assets/img/gadget.svg";

export default function Step11() {
  const { user } = useAppStore();
  const { showDialog } = useDialog();

  const navigate = useNavigate();

  useEvent("close-dialog", () => {
    navigate("/onboarding/12");
  });

  useMount(() => {
    showDialog(
      "El robot que encontraste te permitirá viajar a diferentes regiones.",
      null
    );
  });

  return (
    <>
      <AudioStep11 />
      <div id="step11" className="relative top-0 left-0 w-full h-full">
        {/* <div className="absolute top-[45%] left-[5%] w-[20vw] animate-fly-fast">
          <div className="opacity-0 animate-fade-in">
            <RegionThumbnail region="darklands" />
          </div>
        </div>

        <div className="absolute top-[15%] left-[20%] w-[20vw]  animate-fly-slow">
          <div className="opacity-0 animate-fade-in delay-200">
            <RegionThumbnail region="junkcity" />
          </div>
        </div>

        <div className="absolute top-[15%] left-[60%] w-[20vw] animate-fly-fast delay-200">
          <div className="opacity-0 animate-fade-in delay-100">
            <RegionThumbnail region="ocean-of-feelings" />
          </div>
        </div>

        <div className="absolute top-[45%] left-[75%] w-[20vw]  animate-fly">
          <div className="opacity-0 animate-fade-in delay-50">
            <RegionThumbnail region="prosperity-forest" />
          </div>
        </div> */}

        <div className="absolute top-[35%] md:top-[25%] left-[50%] md:left-[60%] w-[40vw] md:w-[18vw] animate-fly-fast delay-200">
          <div className="opacity-0 animate-fade-in delay-100 rotate-12">
            <img src={gadget} alt="" />
          </div>
        </div>

        <div className="absolute bottom-[-15vw] md:left-32 w-[98vw] md:w-[48vw] right-0 mx-auto flex justify-center">
          <AvatarCharacter responsive />
        </div>
      </div>
    </>
  );
}
