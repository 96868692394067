import { useEffect, useState } from "react";
import { regions } from "utils/regions";

type RegionThumbnailProps = {
  region: "darklands" | "junkcity" | "ocean-of-feelings" | "prosperity-forest";
};

export default function RegionThumbnail({ region }: RegionThumbnailProps) {
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!region) return;
    const regionData = regions.find((item: any) => item.id === region);
    setData(regionData);
  }, [region]);

  if (!data) return <></>;

  return (
    <div className="w-full aspect-square rounded-full bg-white flex items-center justify-center overflow-hidden relative border-2 border-white shadow-glow shadow-white">
      <img
        src={data.thumbnail}
        alt={data.name}
        className="w-full h-full object-cover absolute"
      />
    </div>
  );
}
