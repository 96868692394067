/* eslint-disable react-hooks/exhaustive-deps */
import { useDialog } from "context/DialogContext";
import { useEffect } from "react";
import useSound from "use-sound";

const audioStep = require("assets/audio/onboarding/step_7.mp3");

export default function AudioStep6() {
	const { isMuted } = useDialog();
	const [voiceOver, { stop }] = useSound(audioStep, {
		id: "step6",
		volume: 0.5,
	});

	useEffect(() => {
		if (!isMuted) {
			voiceOver();
		} else {
			stop();
		}
		return () => stop();
	}, [isMuted, voiceOver]);

	return null;
}
