import Gem from "components/gem";
import { useAuth } from "context/AuthContext";
import { useDialog } from "context/DialogContext";
import { useNavigate } from "react-router-dom";
import { useEvent, useMount } from "react-use";
import { useAppStore } from "store";
import AudioStep12 from "./audio";
import "./styles.scss";

export default function Step12() {
	const { user } = useAppStore();
	const { showDialog } = useDialog();
	const navigate = useNavigate();

	useEvent("close-dialog", () => {
		navigate("/onboarding/9");
	});

	useMount(() => {
		showDialog(
			"Este obtiene su poder de 3 tipos de gemas con poderes místicos: las gemas del Conocimiento, las de las Habilidades y las de la Motivación.",
			null,
		);
	});

	return (
		<>
			<AudioStep12 />
			<div id="step12" className="relative top-0 left-0 w-full h-full">
				<div className="absolute top-[30%] left-[10%] w-[20vw] animate-fly-fast">
					<Gem type="knowledge" />
				</div>

				<div className="absolute top-[20%] left-[40%] w-[20vw] animate-fly">
					<Gem type="skill" />
				</div>

				<div className="absolute top-[30%] left-[70%] w-[20vw] animate-fly">
					<Gem type="motivation" />
				</div>

				{/* <div className="absolute bottom-0 left-0 right-0 mx-auto flex justify-center">
        <AvatarCharacter />
      </div> */}
			</div>
		</>
	);
}
