import { AiOutlineLoading } from "react-icons/ai";

export default function Loading() {
  return (
    <div className="loading flex items-center justify-center gap-3 bg-white rounded-xl p-4 shadow">
      <div>
        <AiOutlineLoading
          size={32}
          className="animate-spin text-brand-orange"
        />
      </div>
      <strong>CARGANDO...</strong>
    </div>
  );
}
