import AvatarCreator from "components/avatar-creator";
import { useAuth } from "context/AuthContext";
import domtoimage from "dom-to-image";
import { Button } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { actionLog } from "services/actionlog.service";
import { updateUserProfile, uploadPicture } from "services/profile.service";
import { useAppStore } from "store";
import { globalStyles } from "styles";
import { AvatarCharacterPreset } from "types/user";
import "./styles.scss";

const defaultPreset: AvatarCharacterPreset = {
	skin: 0,
	face: 0,
	expression: 0,
	hair: 0,
	upper: 0,
	lower: 0,
	shoes: 0,
	hand_props: 0,
	body_props: 0,
	accessories: 0,
};

export default function Step4() {
	const { user, refreshUser } = useAppStore();
	const navigate = useNavigate();
	const [avatarPreset, setAvatarPreset] = useState<AvatarCharacterPreset>();
	const [loading, setLoading] = useState(false);

	function handleAvatarChange(preset: AvatarCharacterPreset) {
		setAvatarPreset(preset);
	}

	const updateProfileImage = useCallback(async () => {
		try {
			let profileImage: any = {};
			const scale = 2;
			const node = document.getElementById("avatar-image");
			if (node) {
				const blob = await domtoimage.toJpeg(node, {
					height: node.offsetWidth * (scale * 0.5),
					width: node.offsetWidth * (scale * 0.5),
					style: {
						background: "#f4f4f4",
						transform: `scale(${scale}) translate(${
							node.offsetWidth / 2.25 / scale
						}px, ${node.offsetHeight / 3.25 / scale}px)`,
						"border-radius": 0,
					},
				});

				const uploadResult = await uploadPicture(blob);
				if (uploadResult) {
					const url = uploadResult.url;
					profileImage = {
						image: {
							small: { url },
							medium: { url },
							original: { url },
						},
					};
				}

				if (profileImage) {
					return profileImage;
				}

				return null;
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	const handleSubmit = useCallback(async () => {
		if (!avatarPreset || !user || loading) return;

		setLoading(true);

		const profileImage = await updateProfileImage();

		// update user avatar
		const updatedUser = {
			...user,
			...profileImage,
			extra: { ...user.extra, avatar: avatarPreset },
		};

		await updateUserProfile(updatedUser);
		await refreshUser();
		await actionLog("interaction", {
			type: "onboarding",
			content_id: "start",
		});
		navigate("/onboarding/5");
	}, [avatarPreset, user, loading, updateProfileImage, refreshUser, navigate]);

	useEffect(() => {
		if (!user) return;
		const preset: AvatarCharacterPreset = { ...defaultPreset };
		if (user?.extra.sexo) {
			if (user?.extra.sexo === "Masculino") {
				preset.hair = 4;
			}
		}
		setAvatarPreset(preset);
	}, [user, refreshUser]);

	return (
		<div
			id="step2"
			className="relative top-0 left-0 w-full h-full lg:flex lg:flex-col lg:items-center lg:justify-center"
		>
			<div className="w-full h-full lg:h-auto lg:w-auto lg:rounded-xl flex flex-col items-center md:items-end bg-white p-3 md:p-6 space-y-5">
				<div className="w-full text-center">
					<div className={`leading-none text-center ${globalStyles.header}`}>
						<p>Crea tu avatar</p>
					</div>

					<p>Elige la apariencia que más te guste</p>
				</div>

				<div className="overflow-y-auto relative h-screen md:h-auto">
					<AvatarCreator onboarding onUpdatePreset={handleAvatarChange} />
				</div>

				<Button
					className={`${globalStyles.button.success} ${
						!avatarPreset ? "opacity-50" : ""
					}`}
					onClick={handleSubmit}
				>
					{!loading ? (
						<span className=" font-bold pb-1">CONTINUAR</span>
					) : (
						<span className=" font-bold pb-1">CARGANDO...</span>
					)}
				</Button>
			</div>
		</div>
	);
}
