import { useAuth } from "context/AuthContext";
import "./styles.scss";
import { useDialog } from "context/DialogContext";
import { useEvent, useMount } from "react-use";
import { useNavigate } from "react-router-dom";
import { updateUserProfile } from "services/profile.service";
import AvatarCharacter from "components/avatar-character";
import Gem from "components/gem";
import AudioStep13 from "./audio";
import { useAppStore } from "store";
import { actionLog } from "services/actionlog.service";

export default function Step13() {
  const { user, refreshUser } = useAppStore();
  const { showDialog } = useDialog();
  const navigate = useNavigate();

  useEvent("close-dialog", async () => {
    await updateUserProfile({
      ...user,
      extra: { ...user?.extra, onboarding: true },
    });
    await actionLog("onboarding_start", null, "none");
    await refreshUser();
    navigate("/");
  });

  useMount(() => {
    showDialog(
      "Ganarás diferentes tipos de gemas según los retos y misiones que enfrentes en cada región.",
      null
    );
  });

  return (
    <>
      <AudioStep13 />
      <div
        id="step13"
        className="relative top-0 left-0 w-full h-full overflow-hidden"
      >
        <div className="absolute top-[16%] left-[50%] w-[23vw] md:w-[14vw] animate-fly-fast">
          <Gem type="knowledge" />
        </div>

        <div className="absolute top-[30%] md:top-[35%] left-[70%] w-[22.5vw] md:w-[14.5vw] animate-fly">
          <Gem type="skill" />
        </div>

        <div className="absolute top-[45%] md:top-[55%] left-[50%] md:left-[45%] w-[22vw] md:w-[14vw] animate-fly-slow">
          <Gem type="motivation" />
        </div>

        <div className="absolute w-full md:w-1/2 top-16 md:top-0 -left-8 md:left-12 right-0 flex justify-center">
          <AvatarCharacter responsive />
        </div>
      </div>
    </>
  );
}
