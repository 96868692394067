import { ArrowLeft } from "@phosphor-icons/react";
import AvatarCreator from "components/avatar-creator";
import Decoration from "components/decoration";
import { useAuth } from "context/AuthContext";
import domtoimage from "dom-to-image";
import { Button } from "flowbite-react";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { useMount } from "react-use";
import { actionLog } from "services/actionlog.service";
import { updateUserProfile, uploadPicture } from "services/profile.service";
import { useAppStore } from "store";
import { globalStyles } from "styles";
import { AvatarCharacterPreset } from "types/user";

export default function EditAvatar() {
	const { user, refreshUser } = useAppStore();
	const navigate = useNavigate();
	const [avatarPreset, setAvatarPreset] = useState<AvatarCharacterPreset>();
	const [loading, setLoading] = useState(false);
	const [updated, setUpdated] = useState(false);

	function handleAvatarChange(preset: AvatarCharacterPreset) {
		setAvatarPreset(preset);
	}

	const handleBackButton = () => {
		navigate("/profile");
	};

	const updateProfileImage = useCallback(async () => {
		try {
			let profileImage: any = {};
			const scale = 2;
			const node = document.getElementById("avatar-image");
			if (node) {
				const blob = await domtoimage.toJpeg(node, {
					height: node.offsetWidth * (scale * 0.5),
					width: node.offsetWidth * (scale * 0.5),
					style: {
						background: "#333333",
						transform: `scale(${scale}) translate(${
							node.offsetWidth / 3.25 / scale
						}px, ${node.offsetHeight / 3.25 / scale}px)`,
						"border-radius": 0,
					},
				});

				const uploadResult = await uploadPicture(blob);
				if (uploadResult) {
					const url = uploadResult.url;
					profileImage = {
						image: {
							small: { url },
							medium: { url },
							original: { url },
						},
					};
				}

				if (profileImage) {
					return profileImage;
				}

				return null;
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	const handleSubmit = useCallback(async () => {
		if (!avatarPreset || !user || loading) return;

		setUpdated(false);
		setLoading(true);

		const profileImage = await updateProfileImage();

		// update user avatar
		const updatedUser = {
			...user,
			...profileImage,
			extra: { ...user.extra, avatar: avatarPreset },
		};

		await updateUserProfile(updatedUser);

		actionLog("change_avatar", { presets: avatarPreset });

		await refreshUser();

		setLoading(false);
		setUpdated(true);
	}, [avatarPreset, user, loading, updateProfileImage, refreshUser]);

	useMount(() => {
		setUpdated(false);
	});

	return (
		<>
			<Helmet>
				<title>Edit Avatar</title>
			</Helmet>
			<div className="min-h-screen flex flex-col bg-white">
				<div className="flex-none flex flex-row space-x-4 items-center bg-gray-100 p-4">
					<button onClick={handleBackButton}>
						<ArrowLeft size={32} />
					</button>
					<div className="leading-none">
						<h1 className={`${globalStyles.title} p-0 m-0`}>Tienda Avatar</h1>
						<small>Haz click sobre el artículo que deseas comprar.</small>
					</div>
				</div>
				<div className="flex-1 flex flex-col justify-center">
					<div className="px-2 md:pl-0 md:pr-8 h-full max-h-[calc(100vh-160px)] overflow-y-auto scroll-menu">
						<AvatarCreator onUpdatePreset={handleAvatarChange} />
					</div>
				</div>
				<div className="flex items-center justify-center p-4">
					<Button
						className={`${globalStyles.button.success} ${
							!avatarPreset ? "opacity-50" : ""
						}`}
						onClick={handleSubmit}
					>
						{!loading ? (
							<span className=" font-bold pb-1">GUARDAR</span>
						) : (
							<span className=" font-bold pb-1">CARGANDO...</span>
						)}
					</Button>
				</div>
			</div>
			{updated ? <Decoration /> : null}
		</>
	);
}
