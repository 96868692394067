import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { Button, Modal } from "flowbite-react";
import { url } from "inspector";
import React, { useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import ReactModal from "react-modal";

export function GameModal() {
  const [modalIsOpen, setIsOpen] = useState(false);
  //   const [modalData, setModalData] = useState<any>();
  //   const { refreshUser, user } = useAuth();
  const { openModal, closeModal, modalData } = useModal();
  // const { children } = props;

  ReactModal.setAppElement("#modal");

  function afterOpenModal() {
    //
  }

  function handleCloseModal() {
    closeModal();
  }

  useEffect(() => {
    if (openModal === "game") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={false}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={handleCloseModal}
      contentLabel="Game Modal"
      portalClassName="relative z-50"
      overlayClassName="fixed z-100 top-0 left-0 right-0 bottom-0 bg-black bg-opacity-80 p-6"
      contentElement={() => (
        <>
          <button
            onClick={handleCloseModal}
            className="absolute z-40 bg-brand-red text-white p-2 rounded-full top-2 right-2"
          >
            <IoMdClose size={28} />
          </button>
          <div className="z-20 bg-white ring-2 ring-white shadow-2xl rounded-xl overflow-hidden relative w-full h-full">
            <iframe
              src={modalData}
              width="100%"
              height="100%"
              className="absolute  w-full h-full"
              title="Game Modal"
            />
          </div>
        </>
      )}
    />
  );
}
