export const centeredContent = `w-full flex-1 flex flex-col items-center justify-center`;
export const header = `text-2xl md:text-3xl font-bold text-brand-blue`;
export const title = `text-2xl md:text-3xl font-bold text-brand-blue`;
export const subtitle = `text-xl md:text-2xl text-brand-purple`;
export const button = {
  success: `text-white bg-brand-blue font-bold shadow-inner`,
  danger: `bg-brand-red text-white font-bold shadow-inner`,
};

export const globalStyles = {
  button,
  header,
  title,
  subtitle,
};
