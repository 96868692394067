/* eslint-disable react-hooks/exhaustive-deps */
import { useDialog } from "context/DialogContext";
import { useEffect } from "react";
import useSound from "use-sound";

const audioStep = require("assets/audio/onboarding/step_12.mp3");

export default function AudioStep12() {
  const { isMuted } = useDialog();
  const [voiceOver, { stop }] = useSound(audioStep, {
    id: "step12",
    volume: 0.5,
  });

  useEffect(() => {
    if (!isMuted) {
      voiceOver();
    } else {
      stop();
    }
    return () => stop();
  }, [isMuted, voiceOver]);

  return null;
}
