import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { ArrowLeft } from "@phosphor-icons/react";
import Gem from "components/gem";
import LifeSkillProgress from "components/lifeskill-progress";
import { UserAvatar } from "components/user-avatar";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { useNavigate } from "react-router";
import { useAppStore } from "store";
import { clamp } from "utils/helpers";

export function UserInfo() {
	const navigate = useNavigate();
	const { user, pointsHistory } = useAppStore();
	const { showModal } = useModal();

	function shouldDisplayBackButton() {
		return window.location.pathname !== "/";
	}

	function handleBackButton() {
		navigate("/");
	}

	function handleProfileClick() {
		navigate("/profile");
	}

	return (
		<div className="z-20 absolute top-[2.5vw] left-[2vw] md:top-[1vw] md:left-[1vw] flex flex-row space-x-2">
			{shouldDisplayBackButton() ? (
				<div>
					<button
						className="rounded-xl bg-brand-orange text-white flex items-center h-full p-2"
						onClick={handleBackButton}
					>
						<ArrowLeft size={32} />
					</button>
				</div>
			) : null}

			<div
				className="bg-white rounded-xl p-2 flex items-center space-x-3 hover:cursor-pointer hover:ring-2 hover:ring-brand-orange"
				onClick={() => handleProfileClick()}
			>
				<div className="w-10 h-10 md:w-12 md:h-12 2xl:w-16 2xl:h-16 rounded-full border">
					<UserAvatar data={{ ...user }} responsive={true} />
				</div>

				<div>
					<h1 className="font-bold">{user?.extra.nombreUsuario}</h1>
					<div className="flex gap-2">
						<div className="flex items-center space-x-2">
							<Gem size={18} type="knowledge" />
							<span>{pointsHistory?.knowledge || 0}</span>
						</div>
						<div className="flex items-center space-x-2">
							<Gem size={18} type="skill" />
							<span>{pointsHistory?.skill || 0}</span>
						</div>
						<div className="flex items-center space-x-2">
							<Gem size={18} type="motivation" />
							<span>{pointsHistory?.motivation || 0}</span>
						</div>
					</div>
				</div>
			</div>

			{user?.extra.lifeskill ? (
				<div
					className="bg-white rounded-xl p-2 flex items-center space-x-3 hover:cursor-pointer hover:ring-2 hover:ring-brand-orange"
					onClick={() => showModal("lifeskill")}
				>
					<div className="w-10 h-10 md:w-12 md:h-12 2xl:w-16 2xl:h-16 rounded-full border">
						<LifeSkillProgress
							lifeskill={user?.extra.lifeskill}
							progress={clamp(
								user?.point_categories[`lifeskill_${user.extra.lifeskill}`] ||
									0,
								1,
								100,
							)}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
}
