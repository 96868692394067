import { Link } from "react-router-dom";
import "./styles.scss";
import { Button } from "flowbite-react";

import headline from "assets/img/headline.svg";
import logo from "assets/img/logo.svg";
import Gem from "components/gem";

import Ash from "assets/img/characters/ash.svg";
import Evolbot from "assets/img/characters/evolbot.svg";
import Alma from "assets/img/characters/alma.svg";
import Fortunio from "assets/img/characters/fortunio-happy.svg";
import { useDialog } from "context/DialogContext";
import { useEffect } from "react";

export default function Step1() {
  return (
    <div
      id="step1"
      className="relative top-0 left-0 w-full h-full flex flex-col items-center justify-center overflow-hidden"
    >
      <div className="z-20 absolute top-0 left-0 right-0 bottom-0 m-auto flex flex-col justify-center w-[85vw] h-[85vw] md:h-auto md:w-[43vw] xl:w-[38vw] space-y-6 aspect-square bg-white bg-opacity-80 shadow-glow rounded-full p-20 md:p-20 lg:p-[8vw] animate-in fade-in zoom-in">
        <div className="p-0 md:px-4">
          <img src={headline} alt="ÚNETE A LA AVENTURA" className="w-full" />
        </div>
        <div>
          <img src={logo} alt="CRECER PARA SER" className="w-full" />
        </div>
        <Link to="/onboarding/3">
          <Button
            className="bg-brand-blue uppercase p-0 min-h-0 xl:p-2 animate-wiggle delay-1000"
            fullSized
          >
            <b className="text-xl md:text-3xl">EMPEZAR</b>
          </Button>
        </Link>
      </div>
      <div className="relative w-full h-full">
        <div className="z-10 absolute top-[78.5%] md:top-[45.5%] left-[30%] md:left-[5%] w-[15%] md:w-[13%] animate-fly">
          <div>
            <Gem type="motivation" />
          </div>
        </div>
        <div className="absolute top-[7%] right-[2%] md:right-[8%] w-[12%] md:w-[6%] animate-fly-fast">
          <Gem type="motivation" />
        </div>
        <div className="absolute top-[4%] md:top-[9%] left-[35%] md:left-[25%] w-[10%] md:w-[6%] animate-fly-slow">
          <Gem type="knowledge" />
        </div>
        <div className="absolute bottom-[7%] right-[40%] md:right-[24%] w-[9%] animate-fly-slow">
          <Gem type="knowledge" />
        </div>
        <div className="z-10 absolute top-[30%] right-[2%] w-[9%] animate-fly-very-slow">
          <Gem type="skill" />
        </div>

        <div className="absolute w-[50%] md:w-[33%] top-[5%]  left-[5%] animate-in slide-in-from-top-10 duration-500">
          <img src={Ash} alt="Ash" className="" />
        </div>

        <div className="absolute w-[70%] md:w-[40%] -bottom-[23%] md:-bottom-[35%] -left-[10%] md:left-[10%] animate-in slide-in-from-bottom-10 duration-1000">
          <img src={Alma} alt="Alma" className="" />
        </div>

        <div className="absolute w-[48%] md:w-[28%] top-[8%] md:top-[5%] right-[2%] md:right-[12%] animate-in slide-in-from-bottom-10 duration-1000">
          <img src={Evolbot} alt="Evolbot" className="" />
        </div>

        <div className="absolute w-[47%] md:w-[30%] -bottom-[12%] md:-bottom-[24%] -right-[4%] md:-right-[4%] animate-in slide-in-from-right-10 duration-500">
          <img src={Fortunio} alt="Evolbot" className="" />
        </div>
      </div>
    </div>
  );
}
