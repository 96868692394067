import AvatarCharacter from "components/avatar-character";
import RegionThumbnail from "components/region-thumbnail";
import { useAuth } from "context/AuthContext";
import { useDialog } from "context/DialogContext";
import { useNavigate } from "react-router-dom";
import { useEvent, useMount } from "react-use";
import { useAppStore } from "store";
import AudioStep10 from "./audio";
import "./styles.scss";

export default function Step10() {
	const { user } = useAppStore();
	const { showDialog } = useDialog();
	const navigate = useNavigate();

	useEvent("close-dialog", () => {
		navigate("/onboarding/8");
	});

	useMount(() => {
		showDialog(
			"Una aventura que te llevará a diferentes regiones, cada una representando un aspecto único de la vida, y en las cuales pondrás a prueba tus habilidades, conocimientos y motivación.",
			null,
		);
	});

	return (
		<>
			<AudioStep10 />
			<div id="step10" className="relative top-0 left-0 w-full h-full">
				<div className="absolute top-[45%] left-[5%] w-[20vw] animate-fly-fast">
					<div className="opacity-0 animate-fade-in">
						<RegionThumbnail region="darklands" />
					</div>
				</div>

				<div className="absolute top-[15%] left-[20%] w-[20vw]  animate-fly-slow">
					<div className="opacity-0 animate-fade-in delay-200">
						<RegionThumbnail region="junkcity" />
					</div>
				</div>

				<div className="absolute top-[15%] left-[60%] w-[20vw] animate-fly-fast delay-200">
					<div className="opacity-0 animate-fade-in delay-100">
						<RegionThumbnail region="ocean-of-feelings" />
					</div>
				</div>

				<div className="absolute top-[45%] left-[75%] w-[20vw]  animate-fly">
					<div className="opacity-0 animate-fade-in delay-50">
						<RegionThumbnail region="prosperity-forest" />
					</div>
				</div>

				<div className="absolute bottom-[-15vw] left-20 md:left-32 w-[88vw] md:w-[48vw] right-0 mx-auto flex justify-center">
					<AvatarCharacter responsive />
				</div>
			</div>
		</>
	);
}
