/* eslint-disable no-prototype-builtins */
import Dialog from "components/dialog";
import { set } from "date-fns";
import { is } from "date-fns/locale";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useEvent } from "react-use";
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "utils/storage";

interface DialogContextData {
  showDialog: (data?: any, character?: any, callback?: () => void) => void;
  loadDialog: (DialogId: string, data?: any) => void;
  closeDialog: () => void;
  resetDialog: () => void;
  openDialog?: string;
  dialogData: any;
  isMuted?: boolean;
  setIsMuted?: any;
}

interface DialogProviderProps {
  children: ReactNode;
}

let showDialogTimeout: any = null;

export const DialogContext = createContext({} as DialogContextData);

function DialogProvider({ children }: DialogProviderProps) {
  const [openDialog, setOpenDialog] = useState<string | undefined>();
  const [dialogData, setDialogData] = useState<any>();
  const [isMuted, setIsMuted] = useState<boolean>();

  const showDialog = useCallback(
    (content?: any, character?: any, callback?: () => void) => {
      if (showDialogTimeout) {
        clearTimeout(showDialogTimeout);
      }
      showDialogTimeout = setTimeout(() => {
        let data = Array.isArray(content) ? content : [content];
        // data = data.map((item: any) => {
        //   if (!item.hasOwnProperty("content")) {
        //     return {
        //       content: item,
        //     };
        //   } else {
        //     return item;
        //   }
        // });
        setDialogData({ messages: data, character });
      }, 300);
    },
    []
  );

  function loadDialog(DialogId: string, data?: any) {
    setDialogData(data);
    setOpenDialog(DialogId);
  }

  const closeDialog = useCallback(() => {
    setOpenDialog(undefined);
    setDialogData(undefined);
    dispatchEvent(new CustomEvent("close-dialog"));
  }, []);

  const resetDialog = useCallback(() => {
    clearTimeout(showDialogTimeout);
    setOpenDialog(undefined);
    setDialogData(undefined);
  }, []);

  useEffect(() => {
    if (isMuted === undefined) return;
    setStorageItem("isMuted", isMuted, true);
  }, [isMuted]);

  useEffect(() => {
    const restoreMuted = getStorageItem("isMuted", true);
    // console.log("restoreMuted", restoreMuted);
    setIsMuted(restoreMuted);
  }, []);

  return (
    <DialogContext.Provider
      value={{
        showDialog,
        loadDialog,
        closeDialog,
        resetDialog,
        setIsMuted,
        openDialog,
        dialogData,
        isMuted,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
}

function useDialog() {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
}

export { DialogProvider, useDialog };
