import AvatarCharacter from "components/avatar-character";
import { useAuth } from "context/AuthContext";
import { useDialog } from "context/DialogContext";
import { useNavigate } from "react-router-dom";
import { useEvent, useMount } from "react-use";
import { useAppStore } from "store";
import AudioStep6 from "./audio";
import "./styles.scss";

import gadget from "assets/img/gadget.svg";

export default function Step6() {
	const { user } = useAppStore();
	const { showDialog } = useDialog();
	const navigate = useNavigate();

	useEvent("close-dialog", () => {
		navigate("/onboarding/7");
	});

	useMount(() => {
		showDialog(
			"Descubriste un robot que abría un portal multidimensional.",
			null,
		);
	});

	return (
		<>
			<AudioStep6 />
			<div id="step6" className="relative top-0 left-0 w-full h-full">
				{/* <div className="absolute top-[61%] md:top-[63%] -left-[10.5%] xl:left-[15.5%] w-[10vw] md:w-[3.5vw] animate-pan md:animate-none">
          <div className="rotate-6">
            <img src={gadget} alt="" />
          </div>
        </div> */}

				<div className="absolute bottom-[1vw] left-0 w-[60vw] md:w-[20vw] right-0 mx-auto flex justify-center">
					<AvatarCharacter responsive />
				</div>
			</div>
		</>
	);
}
