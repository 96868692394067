import { ThumbsDown, ThumbsUp } from "@phosphor-icons/react";
import { set } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { actionLog } from "services/actionlog.service";
import { useAppStore } from "store";
import { getRobotRandomMessage } from "./messages";

interface RobotMessageProps {
  className?: string;
}

export default function RobotMessage({ className }: RobotMessageProps) {
  const { host } = useAppStore();

  const [displayMessage, setDisplayMessage] = useState(false);

  const [step, setStep] = useState(0);

  const checkLastMessage = useCallback(async () => {
    // check last message
    // if last message is from user
    // setDisplayMessage(true)
    // else
    // setDisplayMessage(false)
    // console.log("checkLastMessage");
  }, []);

  async function handleOnMessageClick(nextStep: number) {
    setStep(nextStep);

    await actionLog("interaction", {
      type: "robot_message_question",
      content_id: nextStep === 1 ? "good" : "bad",
    });
  }

  async function handleOnActionClick(goto: boolean) {
    // setStep(nextStep);

    if (!goto) {
      setDisplayMessage(false);
      return;
    }

    try {
      const isLocal = window.location.hostname === "localhost";
      let baseUrl = "https://dev.jovensalud.net";

      if (host) {
        baseUrl = `https://${host}`;
      }

      await actionLog("interaction", {
        type: "robot_message_consejo",
        content_id: goto,
      });

      if (!isLocal) {
        window.parent?.postMessage(
          {
            type: "goto",
            url: `/usuario/buscas-consejo/consejeria`,
          },
          "*"
        );
      } else {
        window.open(`${baseUrl}/usuario/buscas-consejo/consejeria`, "_blank");
      }

      setDisplayMessage(false);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    checkLastMessage();

    setTimeout(() => {
      setDisplayMessage(true);
    }, 3000);
  }, [checkLastMessage]);

  if (!displayMessage) return null;

  return (
    <div className={`${className}`}>
      <div className="p-3 bg-white rounded-2xl relative -rotate-12 w-64">
        {!step ? (
          <div>
            <h1 className="mb-2 leading-none">
              {getRobotRandomMessage("welcome")}
            </h1>
            <div className="flex flex-row gap-2 items-center">
              <button
                type="button"
                onClick={() => handleOnMessageClick(1)}
                className="rounded-full p-2 text-white bg-brand-green"
              >
                <FaThumbsUp />
              </button>
              <button
                type="button"
                onClick={() => handleOnMessageClick(2)}
                className="rounded-full p-2 text-white bg-brand-red"
              >
                <FaThumbsDown />
              </button>
            </div>
          </div>
        ) : null}
        {step === 1 ? (
          <div>
            <h1 className="mb-2 leading-none">
              {getRobotRandomMessage("positive")}
            </h1>
            <div className="flex flex-row gap-2 items-center">
              <button
                onClick={() => handleOnActionClick(true)}
                className="rounded-full p-2 text-white bg-brand-green w-16"
              >
                SÍ
              </button>
              <button
                onClick={() => handleOnActionClick(false)}
                className="rounded-full p-2 text-white bg-brand-red  w-16"
              >
                NO
              </button>
            </div>
          </div>
        ) : null}
        {step === 2 ? (
          <div>
            <h1 className="mb-2 leading-none">
              {getRobotRandomMessage("negative")}
            </h1>
            <div className="flex flex-row gap-2 items-center">
              <button
                onClick={() => handleOnActionClick(true)}
                className="rounded-full p-2 text-white bg-brand-green w-16"
              >
                SÍ
              </button>
              <button
                onClick={() => handleOnActionClick(false)}
                className="rounded-full p-2 text-white bg-brand-red  w-16"
              >
                NO
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
