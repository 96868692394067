export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

export function FormatNumber(value: any) {
  try {
    if (isNaN(value)) return 0;
    return Intl.NumberFormat("pt-BR").format(Math.ceil(value));
  } catch (e) {
    return isNaN(value) ? 0 : value;
  }
}

export function _(value: string) {
  return value.replace(/-/g, "_");
}
export function slugify(value: string) {
  return value
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}
