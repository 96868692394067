import { useDialog } from "context/DialogContext";
import { useCallback, useEffect, useRef, useState } from "react";
import Typewriter from "typewriter-effect";

const avatar = require("assets/img/avatar.jpg");

interface DialogProps {
  onClose?: () => void;
}
export default function Dialog({ onClose }: DialogProps) {
  const { dialogData, closeDialog } = useDialog();

  const { messages, character } = dialogData || { messages: [] };

  const [dialogIndex, setDialogIndex] = useState(-1);
  const [dialogFinished, setDialogFinished] = useState(false);

  const typewriterRef = useRef<any>(null);

  const next = useCallback(() => {
    dispatchEvent(new CustomEvent("stop-audio"));

    if (!dialogFinished || !dialogData || !messages.length) return;

    const total = messages.length;
    const nextIndex = dialogIndex + 1;

    if (nextIndex < total) {
      setDialogIndex(nextIndex);
    } else {
      setDialogFinished(true);
      closeDialog();
    }
  }, [dialogData, messages, dialogFinished, dialogIndex, closeDialog]);

  const canContinue = useCallback(() => {
    const result = dialogIndex < messages.length - 1;
    return result;
  }, [messages, dialogIndex]);

  const startDialog = useCallback(() => {
    if (
      !typewriterRef.current ||
      dialogIndex < 0 ||
      !messages ||
      !messages.length
    )
      return;

    const wrapper = document.querySelector(".Typewriter__wrapper");
    if (wrapper) {
      wrapper.innerHTML = "";
    }

    setDialogFinished(false);

    typewriterRef.current
      .typeString(messages[dialogIndex])
      .callFunction(() => {
        setDialogFinished(true);
      })
      .start();
  }, [messages, dialogIndex]);

  useEffect(() => {
    setDialogIndex(-1);
    setDialogFinished(false);
  }, [messages]);

  useEffect(() => {
    startDialog();
  }, [typewriterRef, dialogIndex, startDialog]);

  useEffect(() => {
    dispatchEvent(new CustomEvent("play-dialog", { detail: dialogIndex }));
  }, [dialogIndex]);

  if (!dialogData) return null;

  return (
    <div
      onClick={() => next()}
      className={`${
        dialogFinished ? "cursor-pointer" : "cursor-default"
      } gap-5 fixed flex flex-row items-center left-0 right-0 bottom-0 bg-black z-40 bg-opacity-70 text-white text-lg leading-tight p-5`}
    >
      {dialogData.character ? (
        <div className="hidden md:block md:relative md:top-auto">
          {dialogData.character.image ? (
            <img
              src={dialogData.character.portrait}
              className="rounded-full w-20 h-20 ring-2 ring-white object-cover object-center"
              alt="Character"
            />
          ) : null}
        </div>
      ) : null}

      <div id="dialog-text" className="relative flex-1">
        {dialogData.character ? (
          <b className="text-xl text-brand-orange">
            {dialogData.character.name}
          </b>
        ) : null}
        <Typewriter
          options={{
            delay: 0,
          }}
          onInit={(typewriter) => {
            typewriterRef.current = typewriter;
            setDialogIndex(0);
            startDialog();
          }}
        />
      </div>

      <div className="md:w-36 absolute right-2 -top-10 md:top-auto md:relative">
        {dialogFinished && (
          <span className="text-yellow-300 text-md font-bold animate-pulse text-center block bg-black bg-opacity-75 px-3 py-1 rounded-full">
            {canContinue() ? "CONTINUAR" : "CONTINUAR"}
          </span>
        )}
      </div>
    </div>
  );
}
