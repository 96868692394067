const clear = require("assets/avatar-editor/clear.png");

const hand_props_1 = require("assets/avatar-editor/hand_props/1.png");
const hand_props_2 = require("assets/avatar-editor/hand_props/2.png");
const hand_props_3 = require("assets/avatar-editor/hand_props/3.png");
const hand_props_4 = require("assets/avatar-editor/hand_props/4.png");
const hand_props_5 = require("assets/avatar-editor/hand_props/5.png");
const hand_props_6 = require("assets/avatar-editor/hand_props/6.png");
const hand_props_7 = require("assets/avatar-editor/hand_props/7.png");
const hand_props_8 = require("assets/avatar-editor/hand_props/8.png");
const hand_props_9 = require("assets/avatar-editor/hand_props/9.png");

const body_props_1 = require("assets/avatar-editor/body_props/1.png");
const body_props_2 = require("assets/avatar-editor/body_props/2.png");
const body_props_3 = require("assets/avatar-editor/body_props/3.png");
const body_props_4 = require("assets/avatar-editor/body_props/4.png");
const body_props_5 = require("assets/avatar-editor/body_props/5.png");

const accessories_1 = require("assets/avatar-editor/accessories/1.png");
const accessories_2 = require("assets/avatar-editor/accessories/2.png");
const accessories_3 = require("assets/avatar-editor/accessories/3.png");
const accessories_4 = require("assets/avatar-editor/accessories/4.png");
const accessories_5 = require("assets/avatar-editor/accessories/5.png");
const accessories_6 = require("assets/avatar-editor/accessories/6.png");
const accessories_7 = require("assets/avatar-editor/accessories/7.png");
const accessories_8 = require("assets/avatar-editor/accessories/8.png");
const accessories_9 = require("assets/avatar-editor/accessories/9.png");
const accessories_10 = require("assets/avatar-editor/accessories/10.png");
const accessories_11 = require("assets/avatar-editor/accessories/11.png");
const accessories_12 = require("assets/avatar-editor/accessories/12.png");

const shoes_1 = require("assets/avatar-editor/shoes/1.png");
const shoes_2 = require("assets/avatar-editor/shoes/2.png");
const shoes_3 = require("assets/avatar-editor/shoes/3.png");

const lower_0 = require("assets/avatar-editor/lower/0.png");
const lower_1 = require("assets/avatar-editor/lower/1.png");
const lower_2 = require("assets/avatar-editor/lower/2.png");
const lower_3 = require("assets/avatar-editor/lower/3.png");

const hair_1 = require("assets/avatar-editor/hair/1.png");
const hair_2 = require("assets/avatar-editor/hair/2.png");
const hair_3 = require("assets/avatar-editor/hair/3.png");
const hair_4 = require("assets/avatar-editor/hair/4.png");
const hair_5 = require("assets/avatar-editor/hair/5.png");
const hair_6 = require("assets/avatar-editor/hair/6.png");
const hair_7 = require("assets/avatar-editor/hair/7.png");

const upper_0 = require("assets/avatar-editor/upper/0.png");
const upper_1 = require("assets/avatar-editor/upper/1.png");
const upper_2 = require("assets/avatar-editor/upper/2.png");
const upper_3 = require("assets/avatar-editor/upper/3.png");
const upper_4 = require("assets/avatar-editor/upper/4.png");
const upper_5 = require("assets/avatar-editor/upper/5.png");
const upper_6 = require("assets/avatar-editor/upper/6.png");
const upper_7 = require("assets/avatar-editor/upper/7.png");
const upper_8 = require("assets/avatar-editor/upper/8.png");
const upper_9 = require("assets/avatar-editor/upper/9.png");
const upper_10 = require("assets/avatar-editor/upper/10.png");
const upper_11 = require("assets/avatar-editor/upper/11.png");
const upper_12 = require("assets/avatar-editor/upper/12.png");

const face_0 = require("assets/avatar-editor/face/0.png");
const face_1 = require("assets/avatar-editor/face/1.png");
const face_2 = require("assets/avatar-editor/face/2.png");
const face_3 = require("assets/avatar-editor/face/3.png");
const face_4 = require("assets/avatar-editor/face/4.png");
const face_5 = require("assets/avatar-editor/face/5.png");
const face_6 = require("assets/avatar-editor/face/6.png");

const skin_0 = require("assets/avatar-editor/skin/0.png");
const skin_1 = require("assets/avatar-editor/skin/1.png");
const skin_2 = require("assets/avatar-editor/skin/2.png");
const skin_3 = require("assets/avatar-editor/skin/3.png");

const hair_color_0 = require("assets/avatar-editor/hair_color/0.png");
const hair_color_1 = require("assets/avatar-editor/hair_color/1.png");
const hair_color_2 = require("assets/avatar-editor/hair_color/2.png");
const hair_color_3 = require("assets/avatar-editor/hair_color/3.png");
const hair_color_4 = require("assets/avatar-editor/hair_color/4.png");
const hair_color_5 = require("assets/avatar-editor/hair_color/5.png");
const hair_color_6 = require("assets/avatar-editor/hair_color/6.png");

const upper_color_0 = require("assets/avatar-editor/upper_color/0.png");
const upper_color_1 = require("assets/avatar-editor/upper_color/1.png");
const upper_color_2 = require("assets/avatar-editor/upper_color/2.png");
const upper_color_3 = require("assets/avatar-editor/upper_color/3.png");
const upper_color_4 = require("assets/avatar-editor/upper_color/4.png");

const shoes_color_0 = require("assets/avatar-editor/shoes_color/0.png");
const shoes_color_1 = require("assets/avatar-editor/shoes_color/1.png");
const shoes_color_2 = require("assets/avatar-editor/shoes_color/2.png");
const shoes_color_3 = require("assets/avatar-editor/shoes_color/3.png");
const shoes_color_4 = require("assets/avatar-editor/shoes_color/4.png");
const shoes_color_5 = require("assets/avatar-editor/shoes_color/5.png");
const shoes_color_6 = require("assets/avatar-editor/shoes_color/6.png");

const avatarTabOptions: any = {
  skin: [0, 1, 2, 3],
  face: [0, 1, 2, 3, 4, 5, 6],
  expression: [],
  hair: [0, 1, 2, 3, 4, 5, 6, 7],
  upper: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  lower: [0, 1, 2, 3],
  shoes: [0, 1, 2, 3],
  hand_props: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  body_props: [0, 1, 2, 3, 4, 5],
  accessories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};

const avatarTabColors: any = {
  skin: null,
  face: null,
  expression: null,
  hair: [0, 1, 2, 3, 4, 5, 6],
  upper: [0, 1, 2, 3, 4],
  lower: null,
  shoes: [0, 1, 2, 3, 4, 5, 6],
  hand_props: null,
  body_props: null,
  accessories: null,
};

const avatarTabOptionsImage: any = {
  skin: [skin_0, skin_1, skin_2, skin_3],
  face: [face_0, face_1, face_2, face_3, face_4, face_5, face_6],
  expression: [],
  hair: [clear, hair_1, hair_2, hair_3, hair_4, hair_5, hair_6, hair_7],
  upper: [
    upper_0,
    upper_1,
    upper_2,
    upper_3,
    upper_4,
    upper_5,
    upper_6,
    upper_7,
    upper_8,
    upper_9,
    upper_10,
    upper_11,
    upper_12,
  ],
  lower: [lower_0, lower_1, lower_2, lower_3],
  shoes: [clear, shoes_1, shoes_2, shoes_3],
  hand_props: [
    clear,
    hand_props_1,
    hand_props_2,
    hand_props_3,
    hand_props_4,
    hand_props_5,
    hand_props_6,
    hand_props_7,
    hand_props_8,
    hand_props_9,
  ],
  body_props: [
    clear,
    body_props_1,
    body_props_2,
    body_props_3,
    body_props_4,
    body_props_5,
  ],
  accessories: [
    clear,
    accessories_1,
    accessories_2,
    accessories_3,
    accessories_4,
    accessories_5,
    accessories_6,
    accessories_7,
    accessories_8,
    accessories_9,
    accessories_10,
    accessories_11,
    accessories_12,
  ],
};

const avatarTabColorsImage: any = {
  skin: null,
  face: null,
  expression: null,
  hair: [
    hair_color_0,
    hair_color_1,
    hair_color_2,
    hair_color_3,
    hair_color_4,
    hair_color_5,
    hair_color_6,
  ],
  upper: [
    upper_color_0,
    upper_color_1,
    upper_color_2,
    upper_color_3,
    upper_color_4,
  ],
  lower: null,
  shoes: [
    shoes_color_0,
    shoes_color_1,
    shoes_color_2,
    shoes_color_3,
    shoes_color_4,
    shoes_color_5,
    shoes_color_6,
  ],
  hand_props: null,
  body_props: null,
  accessories: null,
};

const avatarTabPrice: any = {
  skin: null,
  face: [null, null, null, null, null, "D4x6zyl", null],
  expression: [],
  hair: null,
  upper: [
    0,
    0,
    "DJlbeNB",
    "DSAgWL4",
    "DSAg09q",
    "DSAg4qy",
    "D4x5Dj0",
    "D4x57IE",
    "D7RotEX",
    "D7RoC3K",
    "D7RoNnl",
    "D7RoTvX",
    "D7Rpca4",
  ],
  lower: [0, 0, "DSAhkk9", "DSAhnpv"],
  shoes: [0, 0, "DSAhuoY", "DSAhx8N"],
  hand_props: [
    0,
    "DSAhR6H",
    "DSAhU1I",
    "DSAhWYO",
    "DSAh1Jf",
    "DSAh5f4",
    "DSAia8P",
    "DSAiff5",
    "D7RrizA",
    "D7RrqrZ",
  ],
  body_props: [0, 0, "DSAhLji", "DSAhNvt", "D4x9w70", "D7RqZ9b"],
  accessories: [
    0,
    0,
    0,
    "DSAhC37",
    "DSAhFiH",
    "D4x8a97",
    "D4x8wcv",
    "D7RpKRx",
    "D7RpUHR",
    "D7Rp4Py",
    "D7RqgbM",
    "D7Rqr6d",
    "D7RqzOn",
  ],
};

export {
  avatarTabOptions,
  avatarTabOptionsImage,
  avatarTabColors,
  avatarTabColorsImage,
  avatarTabPrice,
};
