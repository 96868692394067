/* eslint-disable react-hooks/exhaustive-deps */
import { useWebsocketNotification } from "context/WebsocketNotificationContext";
import { useCallback, useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { updateUserProfile } from "services/profile.service";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import ReactModal from "react-modal";
import { Button } from "flowbite-react";
import { getRegionById } from "utils/regions";
import { useNavigate } from "react-router";
import { useAppStore } from "store";

const thumbnail_ending = require("assets/img/region/thumbnail-ending.jpg");

export default function OnboardingNotifications() {
  const { message } = useWebsocketNotification();
  const navigate = useNavigate();
  const { user, hasItem, hasFinishedOnboarding } = useAppStore();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  //   const { openModal, closeModal } = useModal();
  //   const { refreshUser, user } = useAuth();
  // const { children } = props;

  ReactModal.setAppElement("#modal");

  function afterOpenModal() {
    //
  }

  function handleCloseModal() {
    navigate(modalData.url);
    setModalData(undefined);
    setIsOpen(false);
  }

  async function closeNotification() {
    setIsOpen(false);
  }

  useEffect(() => {
    // async function updateOnboarding(region: string, nextRegion: string) {
    //   if (!user) return;
    //   await updateUserProfile({
    //     ...user,
    //     extra: {
    //       ...user.extra,
    //       [`onboarding_${region}`]: true,
    //       onboarding_current: nextRegion,
    //     },
    //   });
    //   refreshUser();
    // }

    if (!user || hasFinishedOnboarding()) return;

    dispatchEvent(new CustomEvent("close-dialog"));

    window.postMessage(
      {
        type: "close-achievement",
      },
      "*"
    );

    // console.log(user?.extra);

    if (hasItem("DPSpetX") && !hasItem("DPUwGBr")) {
      //junkcity
      const nextRegion = getRegionById("junkcity");
      setModalData({
        image: nextRegion.image,
        title: "Nueva región desbloqueada!",
        description: nextRegion.name,
        url: `/region/${nextRegion.id}`,
      });
      setIsOpen(true);
    } else if (hasItem("DPUwGBr") && !hasItem("DPUw5Eu")) {
      //ocean
      const nextRegion = getRegionById("ocean-of-feelings");
      setModalData({
        image: nextRegion.image,
        title: "Nueva región desbloqueada!",
        description: nextRegion.name,
        url: `/region/${nextRegion.id}`,
      });
      setIsOpen(true);
    } else if (hasItem("DPUw5Eu") && !hasItem("DPUzkSx")) {
      //forest
      const nextRegion = getRegionById("prosperity-forest");
      setModalData({
        image: nextRegion.image,
        title: "Nueva región desbloqueada!",
        description: nextRegion.name,
        url: `/region/${nextRegion.id}`,
      });
      setIsOpen(true);
    } else if (hasItem("DPUzkSx")) {
      // navigate("/ending/1");
      const nextRegion = {
        image: thumbnail_ending,
        name: "CRECER PARA SER",
      };
      setModalData({
        image: nextRegion.image,
        title: "¡Continúa tu viaje!",
        description: nextRegion.name,
        url: `/ending/1`,
      });
      setIsOpen(true);
    }

    // if(user?.extra.onboarding_darklands && !user?.extra.onboarding_junkcity ) {

    // // Darklands
    // if (message?.item === "DPSpetX") {
    //   console.log("ONBOARDING", message);
    //   const nextRegion = getRegionById("junkcity");
    //   // updateOnboarding("darklands", nextRegion.id);
    //   setModalData({
    //     image: nextRegion.image,
    //     title: "Nueva region desbloqueada!",
    //     description: nextRegion.name,
    //   });
    //   setIsOpen(true);
    // }
    // // Junkcity
    // else if (message?.item === "DPUwGBr") {
    //   const nextRegion = getRegionById("ocean-of-feelings");
    //   // updateOnboarding("junkcity", nextRegion.id);
    //   setModalData({
    //     image: nextRegion.image,
    //     title: "Nueva region desbloqueada!",
    //     description: nextRegion.name,
    //   });
    //   setIsOpen(true);
    // }

    // // Ocean of Feelings
    // else if (message?.item === "DPUw5Eu") {
    //   const nextRegion = getRegionById("prosperity-forest");
    //   // updateOnboarding("ocean_of_feelings", nextRegion.id);
    //   setModalData({
    //     image: nextRegion.image,
    //     title: "Nueva region desbloqueada!",
    //     description: nextRegion.name,
    //   });
    //   setIsOpen(true);
    // }

    // // Prosperity Forest
    // else if (message?.item === "DPUzkSx") {
    //   // updateOnboarding("prosperity_forest", "ending");
    //   setIsOpen(false);
    //   navigate("/ending");
    // }
  }, [user, hasItem, hasFinishedOnboarding]);

  //   if (!modalData || modalData?.length < 1) return null;
  //   const item = modalData[0];

  return (
    <ReactModal
      shouldCloseOnOverlayClick={false}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={handleCloseModal}
      contentLabel="Game Modal"
      portalClassName="relative z-50"
      overlayClassName="fixed z-100 top-0 left-0 right-0 bottom-0 bg-black p-6 flex justify-center items-center bg-vortex bg-opacity-80"
      contentElement={() => (
        <div className="w-1/2 max-w-[500px] rounded-xl bg-white absolute m-auto shadow-glow ">
          {/* <button
            type="button"
            className="absolute -right-5 -top-5  rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
            onClick={() => closeNotification()}
          >
            <IoIosClose color="white" size={30} />
          </button> */}

          {modalData && (
            <div className="flex flex-col items-center gap-4 p-5 animate-in zoom-in-100">
              <h1 className="font-bold text-2xl leading-tight">
                {modalData.title}
              </h1>
              <img
                src={modalData.image}
                alt=""
                className="w-1/2 aspect-square object-cover rounded-full animate-in zoom-in-100"
              />
              <div
                className="relative text-center text-3xl"
                dangerouslySetInnerHTML={{ __html: modalData.description }}
              ></div>
              <Button onClick={handleCloseModal}>CONTINUAR</Button>
            </div>
          )}
        </div>
      )}
    />
  );
}
