import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import { RouteList } from "./routes";

import "react-toastify/dist/ReactToastify.css";
import "assets/fonts/index.scss";
import "./App.scss";

import { ModalProvider } from "context/ModalContext";
import ToastProvider from "context/ToastContext";
import { WebsocketProvider } from "context/WebsocketContext";
import { AchievementModal } from "components/modal/achievement";
import { WebsocketNotificationProvider } from "context/WebsocketNotificationContext";
import { AppContainer } from "components/ui/app-container";
import { HelmetProvider } from "react-helmet-async";
import { DialogProvider } from "context/DialogContext";
import { ViewportProvider } from "context/ViewportContext";
import { GameModal } from "components/modal/game";

const App = () => (
  <HelmetProvider>
    <ViewportProvider>
      <BrowserRouter>
        {/* <AuthProvider> */}
        <WebsocketNotificationProvider>
          <WebsocketProvider>
            <ModalProvider>
              <DialogProvider>
                <AppContainer>
                  <RouteList />
                </AppContainer>
                <ToastProvider />
              </DialogProvider>
            </ModalProvider>
          </WebsocketProvider>
        </WebsocketNotificationProvider>
        {/* </AuthProvider> */}
      </BrowserRouter>
    </ViewportProvider>
  </HelmetProvider>
);

export default App;
