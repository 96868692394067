import { api } from "./api";

export async function checkNotifications() {
  const { data } = await api.get(
    `notification/?player=me&scope=private&published_min=-30d&max_results=10`
  );
  return data;
}

export async function getNotifications(clear = false) {
  const { data } = await api.get(
    `notification?player=me&scope=private&published_min=-30d&max_results=10&delete=true`
  );
  return data
    .map((notification: any) => {
      return {
        time: notification.time,
        content: JSON.parse(notification.definition.content),
      };
    })
    .sort((a: any, b: any) => {
      return a.content.item ? -1 : a.time > b.time ? -1 : 1;
    });
}

export async function clearNotifications() {
  const { data } = await api.get(
    "notification?player=me&scope=private&max_results=10&delete=true"
  );
  return data;
}

export async function getMyNotifications(id: string) {
  const { data } = await api.get(
    `database/notification?q=player.id:'${id}'&max_results=100`
  );
  return data;
}

export async function deleteNotification(id: string) {
  const { data } = await api.delete(`database/notification?q=_id:'${id}'`);
  return data;
}

export async function getAllNotifications(date?: any) {
  const payload = [{ $sort: { created: 1 } }] as any;

  if (date) {
    payload.unshift({
      $match: {
        created: {
          $gt: {
            $date: date,
          },
        },
      },
    });
  }

  const { data } = await api.post(
    `database/notifications__c/aggregate`,
    payload
  );
  if (!Array.isArray(data)) {
    return null;
  } else {
    return data[0];
  }
}
