const knowledge = require("assets/img/gems/knowledge.png");
const skill = require("assets/img/gems/skill.png");
const motivation = require("assets/img/gems/motivation.png");
const sexuality = require("assets/img/life-skills/sexuality.png");
const emotion = require("assets/img/life-skills/emotions.png");
const relationship = require("assets/img/life-skills/relationship.png");
const education = require("assets/img/life-skills/education.png");

const gems: any[] = [
  {
    id: "knowledge",
    name: "Conocimiento",
    image: knowledge,
  },
  {
    id: "skill",
    name: "Habilidades",
    image: skill,
  },
  {
    id: "motivation",
    name: "Motivación",
    image: motivation,
  },
  {
    id: "sexuality",
    name: "Sexualidad",
    image: sexuality,
  },
  {
    id: "emotion",
    name: "Emociones",
    image: emotion,
  },
  {
    id: "relationship",
    name: "Relaciones",
    image: relationship,
  },
  {
    id: "education",
    name: "Educación",
    image: education,
  },
];

export { gems };
