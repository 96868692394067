const robotWelcomeMessage = [
  "¡Hola! ¿Cómo estás?",
  "Oye, ¿qué tal?",
  "¿Cómo va todo?",
  "¡Hey! ¿Cómo estás llevando la vida?",
  "Saludos, ¿cómo va tu mundo?",
];

const robotPositiveMessage = [
  "¡Genial! ¿Quieres compartirlo con nosotros?",
  "¡Super! Cuéntanos las novedades.",
  "¡Bien! Cuéntanos ¿qué hay de nuevo en tu mundo?",
];

const robotNegativeMessage = [
  "¡Lo lamento! ¿Quieres compartir con nosotros lo que te pasa?",
  "Hmmm… Cuéntanos las novedades.",
  "¿Quieres compartirnos cómo tu mundo está patas arriba?",
];

type RobotMessageType = "welcome" | "positive" | "negative";

export function getRobotRandomMessage(type: RobotMessageType) {
  switch (type) {
    case "welcome":
      return robotWelcomeMessage[
        Math.floor(Math.random() * robotWelcomeMessage.length)
      ];
    case "positive":
      return robotPositiveMessage[
        Math.floor(Math.random() * robotPositiveMessage.length)
      ];
    case "negative":
      return robotNegativeMessage[
        Math.floor(Math.random() * robotNegativeMessage.length)
      ];
    default:
      return robotWelcomeMessage[
        Math.floor(Math.random() * robotWelcomeMessage.length)
      ];
  }
}
