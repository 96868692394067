import {
	Backpack,
	GameController,
	Gift,
	Lock,
	LockKey,
	LockLaminated,
	LockSimple,
	Pants,
	Prohibit,
	Smiley,
	Sneaker,
	StopCircle,
	Sunglasses,
	TShirt,
} from "@phosphor-icons/react";
import AvatarCharacter from "components/avatar-character";
import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
/* eslint-disable react-hooks/exhaustive-deps */
import domtoimage from "dom-to-image";
import saveAs from "file-saver";
import { Button } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { BiBody, BiFace, BiNoSignal } from "react-icons/bi";
import { toast } from "react-toastify";
import { actionLog } from "services/actionlog.service";
import { updateUserProfile, uploadPicture } from "services/profile.service";
import { useAppStore } from "store";
import { AvatarCharacterPreset } from "types/user";
import {
	avatarTabColors,
	avatarTabColorsImage,
	avatarTabOptions,
	avatarTabOptionsImage,
	avatarTabPrice,
} from "./avatar-creator-options";

const defaultPreset: AvatarCharacterPreset = {
	skin: 0,
	face: 0,
	expression: 0,
	hair: 0,
	upper: 0,
	lower: 0,
	shoes: 0,
	hand_props: 0,
	body_props: 0,
	accessories: 0,
};

const avatarTabIcons: any = {
	skin: <BiBody size={24} />,
	face: <Smiley size={24} />,
	hair: <BiFace size={24} />,
	upper: <TShirt size={24} />,
	lower: <Pants size={24} />,
	shoes: <Sneaker size={24} />,
	accessories: <Sunglasses size={24} />,
	hand_props: <GameController size={24} />,
	body_props: <Backpack size={24} />,
};

type AvatarCreatorProps = {
	onboarding?: boolean;
	onUpdatePreset?: (preset: AvatarCharacterPreset) => void;
};

export default function AvatarCreator({
	onboarding,
	onUpdatePreset,
}: AvatarCreatorProps) {
	const { showModal } = useModal();
	const { user, refreshUser } = useAppStore();

	const [avatarPreset, setAvatarPreset] =
		useState<AvatarCharacterPreset>(defaultPreset);
	const [activeTab, setActiveTab] = useState("skin");

	const isPurchased = useCallback(
		(good?: any) => {
			if (!user?.catalog_items) return false;
			try {
				const purchased = Object.keys(user?.catalog_items).find((item: any) => {
					return item === good;
				});
				return !!purchased;
			} catch (e) {
				return false;
			}
		},
		[user],
	);

	const isItemEnabled = useCallback(
		(key: string, value: number) => {
			if (!key) return false;
			if (!avatarPreset) return false;
			if (onboarding) {
				if (avatarTabPrice[key] && avatarTabPrice[key][value]) {
					return false;
				}
			} else if (avatarTabPrice[key] && avatarTabPrice[key][value]) {
				return isPurchased(avatarTabPrice[key][value]);
			}
			return true;
		},
		[user, onboarding],
	);

	const updatePresets = useCallback(
		(key: string, value: number) => {
			if (!key || !isItemEnabled(key, value)) {
				showModal("avatar", {
					item: {
						key,
						value,
					},
				});
				return;
			}
			const newAvatarPreset = {
				...avatarPreset,
				[key]: value,
			} as AvatarCharacterPreset;
			setAvatarPreset(newAvatarPreset);
			onUpdatePreset?.(newAvatarPreset);
		},
		[avatarPreset],
	);

	useEffect(() => {
		if (!user) return;
		if (user?.extra.avatar) {
			setAvatarPreset(user.extra.avatar);
		} else if (user?.extra.sexo) {
			if (user?.extra.sexo === "Masculino") {
				updatePresets("hair", 4);
			}
		}
	}, [user]);

	return (
		<div className="relative w-full flex flex-col md:flex-row items-center md:items-stretch justify-center">
			<div className="relative flex-none">
				<div className="w-48 2xl:w-96 aspect-[1/1.6]"></div>

				<div
					id="avatar-image"
					className="absolute w-full h-full -left-1/3 md:left-0 bottom-0 pointer-events-none"
				>
					<AvatarCharacter responsive preset={avatarPreset} />
				</div>
			</div>
			<div className="space-y-6 flex-1 flex flex-col">
				<div className="absolute top-5 right-0 md:relative flex-none grid grid-cols-2 md:grid-cols-9 gap-2">
					{Object.keys(avatarTabIcons).map((key) => (
						<Button
							key={key}
							color={activeTab === key ? "warning" : "light"}
							className="shadow"
							onClick={() => setActiveTab(key)}
						>
							{avatarTabIcons[key]}
						</Button>
					))}
				</div>
				<div className="bg-gray-100 p-3 rounded-xl flex-1">
					<div className="grid grid-cols-6 md:grid-cols-8 gap-3">
						{avatarTabOptions[activeTab].map((tab: any, index: number) => (
							<div key={tab} className="flex items-center justify-center">
								<div
									className="relative cursor-pointer hover:ring-2 shadow bg-white rounded-full w-full aspect-square flex items-center justify-center"
									onClick={() => updatePresets(activeTab, tab)}
								>
									{!isItemEnabled(activeTab, tab) ? (
										<div className="absolute p-1 flex items-center justify-center rounded-full z-10 -right-2 -bottom-2 bg-brand-purple text-white ring-2 ring-white">
											<Gift size={24} />
										</div>
									) : null}
									<img
										src={avatarTabOptionsImage[activeTab][tab]}
										className={`w-full h-full object-cover rounded-full overflow-hidden`}
										alt=""
									/>
								</div>
							</div>
						))}
					</div>
					{avatarTabColors[activeTab] && avatarTabColors[activeTab].length ? (
						<div>
							<h1 className="text-xl mt-4 mb-2">
								<strong>Colores</strong>
							</h1>
							<div className="grid grid-cols-6 md:grid-cols-12 gap-3">
								{avatarTabColors[activeTab].map((tab: any) => (
									<div key={tab} className="flex items-center justify-center">
										<div
											className="cursor-pointer hover:ring-2 shadow bg-white rounded-full w-full aspect-square flex items-center justify-center"
											onClick={() => updatePresets(`${activeTab}_color`, tab)}
										>
											<img
												src={avatarTabColorsImage[activeTab][tab]}
												className="w-full h-full object-cover rounded-full overflow-hidden"
												alt=""
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}
