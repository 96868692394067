import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import ReactModal from "react-modal";

import badgeAnimation from "assets/lottie/trophy.json";
import { getAchievements } from "services/achievement.service";
import { getChallengeById } from "services/challenges.service";
import { Challenge } from "types/funifier";
import { Button } from "flowbite-react";
import { useAppStore } from "store";
import Decoration from "components/decoration";
import Gem from "components/gem";
import { clearNotifications } from "services/notifications.service";

export function AchievementModal(props: any) {
  // const modalID = "achievement";

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const { user, refreshUser } = useAppStore();

  // const { openModal, closeModal } = useContext(ModalContext);
  // const { children } = props;

  ReactModal.setAppElement("#modal-achievement");

  async function handleCloseModal() {
    await clearNotifications();
    setIsOpen(false);
  }

  function afterOpenModal() {}

  async function getItemDetails(achievement: any) {
    // console.log("ITEM", achievement);
    try {
      if (achievement.type === "achievement" || achievement.type === 1) {
        const data: any = achievement.item
          ? await getChallengeById(achievement.item)
          : achievement;
        // console.log("DATA", data.techniques);
        setModalData({
          title:
            data.techniques && data.techniques.includes("GT02")
              ? `¡Insignia conseguida!`
              : `¡Misión cumplida!`,
          name: data.title || data.challenge,
          description: data.description,
          image: data.badge ? data.badge?.medium.url : null,
        });
        setIsOpen(true);
      } else {
      }
    } catch (e) {
      console.error(e);
    }
  }

  // useEffect(() => {
  //   console.log(modalData);
  // }, [modalData]);

  useEffect(() => {
    const listener = async ({ data }: any) => {
      if (data.type === "achievement") {
        // setIsOpen(true);
        // setModalData(data.value);
        if (data.value && data.value.achievement) {
          getItemDetails(data.value.achievement);
        }
        refreshUser();
      } else if (data.type === "close-achievement") {
        setModalData(undefined);
        setIsOpen(false);
      } else if (data.title) {
        setModalData(data);
        setIsOpen(true);
      }
    };

    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, [refreshUser]);

  // if (!user) return null;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 px-10 w-[450px] flex flex-col items-center flex-none mx-5">
              <h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
                {modalData?.title || `¡Misión cumplida!`}
              </h1>

              {modalData ? (
                <div className="relative w-[200px]">
                  {modalData?.image ? (
                    <img
                      src={modalData?.image}
                      alt=""
                      className="w-full h-[200px] object-contain animate-wiggle"
                    />
                  ) : (
                    <div className="w-full h-[200px] relative animate-wiggle-zoom">
                      <div className="absolute top-[13%] -rotate-12">
                        <Gem type="knowledge" size={75} />
                      </div>
                      <div className="absolute left-[26%] bottom-[9%] rotate-6">
                        <Gem type="motivation" size={70} />
                      </div>
                      <div className="absolute top-[15%] right-0 rotate-3">
                        <Gem type="skill" size={80} />
                      </div>
                    </div>
                  )}
                </div>
              ) : null}

              <div>
                {modalData?.name && (
                  <p className="text-orange-500 font-bold text-center text-xl">
                    {modalData?.name}
                  </p>
                )}

                {modalData?.description && (
                  <p className="text-gray-500 text-center">
                    {modalData?.description}
                  </p>
                )}
              </div>

              <div className="py-3 relative z-10">
                <Button
                  fullSized
                  type="button"
                  onClick={() => handleCloseModal()}
                >
                  CONTINUAR
                </Button>
              </div>
            </div>
          </div>
        )}
      />
      {modalIsOpen ? <Decoration /> : null}
    </>
  );
}
