import { useState, useEffect } from "react";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";

export default function FullscreenButton() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    if (typeof document === "undefined") return;

    setIsSupported(document.fullscreenEnabled);
  }, []);

  function toggleFullscreen() {
    if (typeof document === "undefined") return;

    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    }
  }

  if (!isSupported) return null;

  return (
    <div className="fullscreen-button fixed top-0 right-0 z-40 bg-white">
      <button
        onClick={toggleFullscreen}
        className="w-10 h-10 flex flex-row items-center justify-center bg-brand-orange text-white"
      >
        {isFullscreen ? (
          <BiExitFullscreen size={32} />
        ) : (
          <BiFullscreen size={32} />
        )}
      </button>
    </div>
  );
}
