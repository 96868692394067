import { api } from "./api";

export async function fetchChallenges(filters?: any) {
  const { data } = await api.get(`challenge`, {
    params: {
      orderby: "extra.order",
      technique: "GT35",
      q: `'extra.hidden':null,active:true${filters ? "," + filters : ""}`,
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data.filter((item) => item.techniques.includes("GT35"));
  }
}

export async function getAllChallenges(filters?: any) {
  const { data } = await api.get(`challenge`, {
    params: {
      orderby: "extra.order",
      reverse: true,
      q: "'extra.hidden':null,active:true",
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getChallengeById(id?: any) {
  const { data } = await api.get(`challenge`, {
    params: {
      id,
    },
  });
  if (!Array.isArray(data)) {
    return data;
  } else {
    return data[0];
  }
}
