/* eslint-disable react-hooks/exhaustive-deps */

import { UserInfo } from "components/user-info";
import { useLocation, useNavigate } from "react-router";

import { useDialog } from "context/DialogContext";
import { useCallback, useEffect, useState } from "react";

import { Gift, Shield } from "@phosphor-icons/react";
import OnboardingMusic3 from "components/audio/music/onboarding3";
import CharacterThumbnail from "components/character-thumbnail";
import { useModal } from "context/ModalContext";
import { useEvent, useMount } from "react-use";
import { useAppStore } from "store";
import { regions } from "utils/regions";
import AudioHomeOnboarding1 from "./components/onboarding_1";
import "./styles.scss";

import gadget from "assets/img/gadget.svg";
import RobotMessage from "components/robot-message";
import { IRegionContent } from "models";
import { getRandomRegion } from "pages/Region/content";
import { getChallengeById } from "services/challenges.service";
import { getNotifications } from "services/notifications.service";

let dialogTimeout: any = null;

const randomRegion = getRandomRegion();

export function Home() {
	const {
		user,
		token,
		refreshUser,
		hasItem,
		hasFinishedOnboarding,
		shouldRedirect,
		setShouldRedirect,
	} = useAppStore();
	const { showDialog, resetDialog } = useDialog();
	const navigate = useNavigate();
	const { showModal } = useModal();
	const location = useLocation();

	const [randomMissionMessage, setRandomMissionMessage] = useState<any>();

	const [regionList, setRegionList] = useState(regions);

	useEvent("close-dialog", () => {});

	const isCurrentRegion = useCallback(
		(regionId: string) => {
			if (!user) return false;

			if (hasFinishedOnboarding()) return false;

			// if (hasItem("DPUw5Eu") && regionId === "prosperity-forest") {
			// 	return true;
			// } else if (
			// 	hasItem("DPUwGBr") &&
			// 	!hasItem("DPUw5Eu") &&
			// 	regionId === "ocean-of-feelings"
			// ) {
			// 	return true;
			// } else if (
			// 	hasItem("DPSpetX") &&
			// 	!hasItem("DPUwGBr") &&
			// 	regionId === "junkcity"
			// ) {
			// 	return true;
			// } else if (!hasItem("DPSpetX") && regionId === "darklands") {
			// 	return true;
			// }

			return false;
		},
		[user],
	);

	const getAudio = useCallback(() => {
		if (!user) return;
		if (user.extra?.onboarding && !hasFinishedOnboarding()) {
			return <AudioHomeOnboarding1 />;
		}
	}, [user, hasItem]);

	const getMusic = useCallback(() => {
		if (!user) return;
		if (user.extra?.onboarding && !hasFinishedOnboarding()) {
			return <OnboardingMusic3 />;
		}
	}, [user]);

	const isRegionOpen = useCallback(
		(regionId: string) => {
			if (!user) return false;
			return true;
			// if (regionId === "darklands") return true;
			// if (regionId === "junkcity" && hasItem("DPSpetX")) return true;
			// if (regionId === "ocean-of-feelings" && hasItem("DPUwGBr")) return true;
			// if (regionId === "prosperity-forest" && hasItem("DPUw5Eu")) return true;
			// return false;
		},
		[user, hasItem],
	);

	const goToRegion = useCallback(
		(region: any) => {
			if (isRegionOpen(region.id)) {
				navigate(`/region/${region.id}`);
			} else {
				resetDialog();
				showDialog([
					`Esta región aún no está abierta. ¡Vuelve a intentarlo más tarde!`,
				]);
			}
		},
		[resetDialog, showDialog, navigate, isRegionOpen],
	);

	const getRandomMissionMessage = (region: IRegionContent) => {
		const titles = [
			"¡Hora de Jugar!",
			"¡Viaja por las regiones!",
			"¡Descubre nuevas misiones y retos!",
			"¡Desbloquea conocimientos perdidos!",
		];
		const generic = [
			"Continúa el viaje y domina nuevas habilidades.",
			"Sé el héroe de tu propia historia. Sigue creciendo con nuestras misiones.",
			"¡No te detengas, hay más misiones por descubrir!",
		];
		const darklands = [
			"Sigue conquistando mis Tierras Oscuras",
			"Trae la luz a las Tierras Oscuras",
			"¡No te detengas, hay más misiones por descubrir en Tierras Oscuras!",
			"¡Vuela conmigo, Ash, y conquistemos Tierras Oscuras!",
		];
		const junkcity = [
			"¡Evolbot te necesita en Ciudad Chatarra! Transforma esta ciudad en un lugar mejor.",
			"¡Unámonos para cambiar Ciudad Chatarra y hacerla brillar!",
			"Continúa tu viaje en Ciudad Chatarra e impacta tu vida.",
		];
		const ocean_feelings = [
			"¡Sumérgete en el misterioso mundo submarino de Alma, la Sirena!",
			"¡Desbloquea los secretos del océano mientras juegas y aprendes!",
			"¡Descubre los secretos de las emociones conmigo, Alma, la Sirena!",
		];
		const prosperity_forest = [
			"¡Fortunio te espera en el Bosque de la Prosperidad para crecer juntos!",
			"¡Explora el Bosque de la Prosperidad y alcancemos nuestras metas juntos!",
			"Acompáñame en el Bosque de la Prosperidad y descubramos su magia!",
		];

		const messages = [...generic];

		if (region.id === "darklands") {
			messages.push(...darklands);
		} else if (region.id === "junkcity") {
			messages.push(...junkcity);
		} else if (region.id === "ocean-of-feelings") {
			messages.push(...ocean_feelings);
		} else if (region.id === "prosperity-forest") {
			messages.push(...prosperity_forest);
		}

		setRandomMissionMessage({
			...region,
			title: titles[Math.floor(Math.random() * titles.length)],
			message: messages[Math.floor(Math.random() * messages.length)],
		});
	};

	useEffect(() => {
		resetDialog();

		if (dialogTimeout) {
			clearTimeout(dialogTimeout);
		}

		if (!!user) {
			if (hasFinishedOnboarding()) {
				resetDialog();

				if (!user.extra.lifeskill) {
					showModal("lifeskill");
				}
			}

			if (user.extra?.onboarding) {
				if (!hasFinishedOnboarding()) {
					if (hasItem("DPUzkSx")) {
						//navigate("/ending/1");
					} else {
						dialogTimeout = setTimeout(() => {
							showDialog(`Ingresa a una región.`);
						}, 100);
					}
				}
			} else {
				navigate("/onboarding/1");
			}
		}
	}, [user, resetDialog, showDialog]);

	useMount(() => {
		refreshUser();
	});

	useEvent("message", ({ data }) => {
		if (!user || !hasFinishedOnboarding() || !user.extra.lifeskill) return;

		// console.log("RECEIVED MESSAGE", data);
		if (data.source === "funifier-widget") {
			if (data.type === "origin" && data.value) {
				navigate(`/region/${data.value}`);
			}
		}
	});

	useEffect(() => {
		if (shouldRedirect) {
			// console.log("SHOULD REDIRECT TO", shouldRedirect);
			window.parent.postMessage(
				{
					source: "funifier-widget",
					type: "get-origin",
				},
				"*",
			);
			setShouldRedirect(false);
		}
	}, [shouldRedirect, location]);

	useEffect(() => {
		if (randomRegion) {
			getRandomMissionMessage(randomRegion);
		}
	}, [randomRegion]);

	useEffect(() => {
		async function loadNotifications() {
			try {
				const notifications = await getNotifications();
				if (!notifications || !notifications.length) return;
				const notification = notifications[0];
				// console.log("loadNotifications", notification);
				if (notification.content.title && !notification.content.item) {
					showModal("badge", {
						challenge: notification.content.title,
						description: notification.content.description,
					});
				} else if (notification.content.item) {
					const badge = await getChallengeById(notification.content.item);
					if (!badge.techniques || !badge.techniques.includes("GT35")) {
						showModal("badge", badge);
					}
				}
			} catch (e) {}
		}
		if (token && hasFinishedOnboarding() && user?.extra.lifeskill) {
			loadNotifications();
		}
	}, [token, user, hasFinishedOnboarding]);

	if (!user || !user.extra?.onboarding) {
		return (
			<>
				<div id="home" className={`absolute top-0 left-0 w-full h-full`}></div>
			</>
		);
	}

	function renderOnboarding() {
		return (
			<div className="w-full md:w-3/4 pt-[22vw] md:pt-[9vw]">
				<div className="box w-full p-10 md:p-16 md:pt-10 xl:pt-16">
					<h1 className="text-white font-bold text-center relative z-20 pb-8 leading-tight 2xl:text-2xl">
						Ingresa a una región:
					</h1>
					<div className="grid gap-6 grid-cols-2 md:grid-cols-4 lg:container-md items-center justify-center ">
						{regionList.map((region) => (
							<div
								key={region.id}
								onClick={() => goToRegion(region)}
								className={`relative group`}
							>
								<div
									className={`ring ring-white w-full aspect-square rounded-full bg-white flex items-center justify-center overflow-hidden relative cursor-pointer animate transition-transform hover:scale-105 hover:ring hover:ring-white hover:shadow-glow ${
										!isRegionOpen(region.id) ? "saturate-0 opacity-80" : ""
									}
    
    ${
			isCurrentRegion(region.id)
				? "animate-wiggle-zoom ring-4 ring-brand-green"
				: ""
		}`}
								>
									<img
										src={region.thumbnail}
										alt={region.name}
										className={`w-full h-full object-cover absolute`}
									/>
								</div>
								{
									<label
										className={`absolute z-10 left-0 right-0 top-100 mt-4 bg-brand-red text-white p-2 rounded-xl text-center text-sm opacity-0 pointer-events-none group-hover:opacity-100 ${
											isCurrentRegion(region.id) ? "opacity-100" : ""
										}`}
									>
										<span>{region.name}</span>
									</label>
								}
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}

	function renderDashboard() {
		return (
			<div className="flex-1 flex-col md:flex-row flex w-full p-2 gap-3 pt-[22vw] md:pt-[9vw]">
				<div className="basis-1/2 box">
					<h1 className="text-white font-bold text-center relative z-20 p-8 leading-tight 2xl:text-2xl">
						Completa misiones dentro de las regiones para desbloquear nuevos
						logros.
					</h1>
					<div className="px-10 md:px-20 2xl:px-32 pb-10">
						<div className="grid gap-6 grid-cols-2 md:grid-cols-2 lg:container-md items-center justify-center ">
							{regionList.map((region) => (
								<div
									key={region.id}
									onClick={() => goToRegion(region)}
									className={`relative group`}
								>
									<div
										className={`ring ring-white w-full aspect-square rounded-full bg-white flex items-center justify-center overflow-hidden relative cursor-pointer animate transition-transform hover:scale-105 hover:ring hover:ring-white hover:shadow-glow ${
											!isRegionOpen(region.id) ? "saturate-0 opacity-80" : ""
										}
    
    ${
			isCurrentRegion(region.id)
				? "animate-wiggle-zoom ring-4 ring-brand-green"
				: ""
		}`}
									>
										<img
											src={region.thumbnail}
											alt={region.name}
											className={`w-full h-full object-cover absolute`}
										/>
									</div>
									{
										<label
											className={`absolute z-10 left-0 right-0 bottom-0 leading-tight mt-4 bg-brand-red text-white p-2 rounded-xl text-center text-sm opacity-0 pointer-events-none group-hover:opacity-100 ${
												isCurrentRegion(region.id) ? "opacity-100" : ""
											}`}
										>
											<span>{region.name}</span>
										</label>
									}
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="basis-1/2 box relative">
					<div className="absolute z-30 -top-8 2xl:-top-20 left-5 w-[18vw] md:w-[10vw] animate-fly-fast delay-200">
						<div className="opacity-0 animate-fade-in delay-100 rotate-12 relative">
							<RobotMessage className="absolute -top-10 left-full" />
							<img src={gadget} alt="" />
						</div>
					</div>

					<h1 className="text-white font-bold text-center relative z-20 p-8 pb-0 leading-tight 2xl:text-2xl">
						Sugerencias del robot
					</h1>
					<div className="py-10 px-5 flex flex-col gap-4">
						{randomMissionMessage ? (
							<div
								onClick={() => navigate(`/region/${randomRegion.id}`)}
								className="flex gap-3 items-center bg-white rounded-xl shadow p-3  cursor-pointer hover:ring hover:ring-brand-orange"
							>
								<div className="w-12">
									<CharacterThumbnail region={randomRegion.id} />
								</div>
								<div className="leading-tight">
									<h1 className="font-bold">{randomMissionMessage.title}</h1>
									<small>{randomMissionMessage.message}</small>
								</div>
							</div>
						) : null}

						{/* <div className="flex gap-3 items-center bg-white rounded-xl shadow p-3">
              <div>
                <Chat size={32} />
              </div>
              <div className="leading-tight">
                <h1 className="font-bold">Buscas Consejo</h1>
                <small>
                  Evolbot tiene un consejo para ti, ¿quieres escucharlo?
                </small>
              </div>
            </div> */}

						<div
							onClick={() => navigate("/edit-avatar")}
							className="flex gap-3 items-center bg-white rounded-xl shadow p-3 cursor-pointer hover:ring hover:ring-brand-orange"
						>
							<div className="z-10 w-12 h-12 flex items-center justify-center aspect-square rounded-full bg-white ring-1 text-brand-red">
								<Gift size={30} />
							</div>
							<div className="leading-tight">
								<h1 className="font-bold">Tienda Avatar</h1>
								<small>Compra nuevos artículos para tu avatar</small>
							</div>
						</div>

						<div
							onClick={() => navigate("/badges")}
							className="flex gap-3 items-center bg-white rounded-xl shadow p-3 cursor-pointer hover:ring hover:ring-brand-orange"
						>
							<div className="z-10 w-12 h-12 flex items-center justify-center aspect-square rounded-full bg-white ring-1 text-brand-orange">
								<Shield size={30} />
							</div>
							<div className="leading-tight">
								<h1 className="font-bold">Insignias</h1>
								<small>Revisa todas tus insignias</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			{getAudio()}
			{getMusic()}
			<div
				id="home"
				className={`absolute top-0 left-0 w-full h-full flex flex-col items-center md:justify-center overflow-y-auto overflow-x-hidden md:overflow-hidden scroll-wrapper`}
			>
				<UserInfo />

				{hasFinishedOnboarding() ? renderDashboard() : renderOnboarding()}
			</div>
			{/* <Outlet /> */}
		</>
	);
}
