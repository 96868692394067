import { IRegionContent } from "models";
import { getRegionById } from "utils/regions";

import fortunio from "assets/img/characters/fortunio.svg";
import fortunioHappy from "assets/img/characters/fortunio-happy.svg";

const ash = require("assets/img/characters/ash.png");
const evolbot = require("assets/img/characters/evolbot.png");
const alma = require("assets/img/characters/alma.png");

const ashHappy = require("assets/img/characters/ash-happy.png");
const evolbotHappy = require("assets/img/characters/evolbot-happy.png");
const almaHappy = require("assets/img/characters/alma-happy.png");

// infer from content
const content: any = {
  darklands: {
    id: "darklands",
    character: {
      name: "Ash",
      image: ash,
      imageHappy: ashHappy,
      portrait: getRegionById("darklands").portrait,
    },
    onboarding: [
      "¡Has llegado al inframundo! Mi nombre es Ash, aquí en Tierras Oscuras, la oscuridad no es lo único que te acecha. Encontrarás peligros a la vuelta de cada esquina.",
      "¡Ohh! Pero ten cuidado porque el que juega con fuego se puede quemar.",
    ],
    dialog: [
      "¿Quién eres para molestar al poderoso Ash?",
      "¡Tus dudas te restan poder!",
      "Si quieres jugar con fuego, necesitarás más que un par de guantes de cocina.",
      "La derrota es solo temporal. Me levantaré de nuevo, con una nueva perspectiva y un nuevo propósito.",
      "¿Estás seguro de que quieres desafiarme? Soy un dragón, no un gatito.",
      "Si puedes correr lo suficientemente rápido, tal vez puedas evitar que te queme.",
      "¿Tienes lo que se necesita para enfrentarte a un verdadero dragón? Bueno, vamos a descubrirlo",
      "¿Quién hubiera pensado que las batallas más importantes se ganan con la estrategia y la inteligencia, y no con el tamaño y la fuerza?",
      "Estoy pensando en convertirme en un dragoncito zen. Tal vez comience a practicar yoga y meditación. ¿Qué te parece?",
      "Los desafíos son como tesoros, solo los valientes pueden obtenerlos.",
      "Si quieres volar conmigo, tienes que tener alas más grandes que las mías.",
      "¿Crees que eres valiente? Prueba a enfrentarte a mi mirada.",
      "La oscuridad no es mi enemiga, es mi aliada. ¿Y tú?",
      "Si alguna vez necesitas un poco de calor, solo tienes que acercarte a mí. Pero ten cuidado, no te quemes.",
    ],
  },
  junkcity: {
    id: "junkcity",
    character: {
      name: "Evolbot",
      image: evolbot,
      imageHappy: evolbotHappy,
      portrait: getRegionById("junkcity").portrait,
    },
    onboarding: [
      "¡Saludos Chatarrin! Has llegado a la tierra de los excesos! La Ciudad Chatarra! Soy Evolbot, estoy aquí para satisfacer todos tus antojos.",
      "¿Qué tal un dulce para darte un subidón durante horas?… Si logras resistir las tentaciones, podrás acumular gemas valiosas y evolucionar junto a mí hacia un futuro mejor.",
    ],
    dialog: [
      "Un chocolate es la respuesta a todo, incluso a la pregunta de ¿cómo vas a conseguir todo lo que tienes que hacer hoy?",
      "¿Por qué preocuparse por el mundo si aquí tienes todo lo que necesitas? Pero, ¡ey! A veces es bueno salir y ver algo de sol.",
      "¿Listos para luchar contra las tentaciones? No se preocupen, yo estaré aquí para animarlos.",
      "Nada como videojuegos y comida chatarra para relajarse, pero tampoco es mala idea estirar las piernas de vez en cuando.",
      "¿Amigos o seguidores en redes sociales? ¡Por qué no ambos! Pero recuerda que la gente de carne y hueso no tiene botón de 'me gusta'.",
      "Explorar el mundo en tu pantalla está bien, pero también hay que desconectar y sentir el viento en la cara.",
      "¿Pastilla y a olvidar? Mejor un equilibrio entre cuidarse y darse algún capricho de vez en cuando. ¡La vida es corta y hay que disfrutarla!",
      "¡Nada como tener muchos seguidores en redes sociales! Pero recuerda, en la vida real también existen personas que valen la pena conocer. Y lo mejor de todo, ¡no necesitas usar un filtro para verte bien!",
      "¿Explorar el mundo en línea o en persona? Nada se compara con el olor de la naturaleza y el aire fresco. ¡Sal de tu cueva y descubre el mundo!",
      "El alcohol, las drogas y el tabaco no te harán más guapo, rico o popular. A menos que consideres los problemas de salud, las multas y el rechazo social como algo genial.",
      "La tentación es como una invitación al lado oscuro. Pero no te preocupes, soy tu robot Jedi personal para ayudarte a resistir.",
      "Si resistes las tentaciones, no solo ganarás gemas, también ganarás el título de Chatarrin Fuerte.",
      "Resiste, aquí las tentaciones son como moscas en la basura!",
    ],
  },
  "ocean-of-feelings": {
    id: "ocean-of-feelings",
    character: {
      name: "Alma",
      image: alma,
      imageHappy: almaHappy,
      portrait: getRegionById("ocean-of-feelings").portrait,
    },
    onboarding: [
      "¡Oh, mira lo que me trajo el mar! ¿Qué estás buscando en este océano de las emociones?  ¡Quédate aquí conmigo, no me dejes sola!",
      "¿Crees que podremos enfrentar nuestros miedos?... ¡Solo se trata de luchar contra nuestros propios demonios internos, mientras tratamos de no ahogarnos en el intento!",
    ],
    dialog: [
      "¿Te gusta el drama? ¡Aquí tenemos más giros y vueltas que una montaña rusa!",
      "No te preocupes por ahogarte en mis aguas, ¡mis abrazos son como flotadores salvavidas! ",
      "Si no te gusta algo, cámbialo. Si no puedes cambiarlo, cambia tu actitud. No te quejes.",
      "A veces, para lograr lo que queremos, debemos sumergirnos en lo desconocido y tener fe en nuestra capacidad para encontrar nuestro camino.",
      "Recuerda que, incluso en las aguas más turbulentas, siempre hay una corriente que te llevará a donde necesitas ir.",
      "Cada vez que sientas que te falta el aire, recuerda que eres más fuerte de lo que crees y encontrarás la fuerza para seguir adelante.",
      "No importa cuán tas veces te caigas, siempre hay una oportunidad para levantarte y seguir nadando hacia adelante.",
      "Las perlas más valiosas se encuentran en las profundidades más oscuras. No tengas miedo de explorar tus propias profundidades y descubrir la belleza que hay en ti.",
      "No importa cuán profundo sea el mar de la vida, siempre hay una luz que guía nuestro camino. Sigue esa luz y nunca te perderás.",
      "La vida es demasiado corta para estar triste. Así que... ¡Sonríe, baila y come pastel!",
      "No te rindas ante las tormentas, aprende a surfearlas y saca lo mejor de ellas",
    ],
  },
  "prosperity-forest": {
    id: "prosperity-forest",
    character: {
      name: "Fortunio",
      image: fortunio,
      imageHappy: fortunioHappy,
      portrait: getRegionById("prosperity-forest").portrait,
    },
    onboarding: [
      "¡Hola, hola, hola! Soy Fortunio y aquí todo es fácil, cómodo y sin esfuerzo...¿Para qué sudar la gota gorda trabajando por lo que quieres?",
      "¿Qué era lo que estábamos hablando?... Ah, no importa, ven descansa conmigo y siéntate a jugar. A ver si logras más gemas que yo.",
    ],
    dialog: [
      "Recuerda que estoy contigo. A menos que esté en el baño o haciendo una siesta.",
      "¡No te rindas! Siempre puedes tomar una siesta y seguir intentándolo después.",
      "No te preocupes por los fracasos, preocúpate por las oportunidades que pierdes por no intentarlo.",
      "Si te pierdes en el bosque, no te preocupes, solo sigue las migajas de pan de jengibre, ¡oh espera, eso era otra historia!.",
      "¡No te rindas, las mejores cosas de la vida suceden cuando menos te lo esperas!.",
      "El fracaso no es el final, es solo una oportunidad para empezar de nuevo con más experiencia y sabiduría.",
      "¡Si te caes, al menos cae con estilo!",
      "Confía en ti... aunque si necesitas un empujoncito extra, siempre puedo traer palomitas y una película motivadora.",
      "¡Sigue así! Tu perseverancia es tan admirable que hasta yo me siento inspirado.",
      "Mírate, ¡eres un éxito! Y yo aquí, todavía intentando recordar mi propia dirección.",
      "Estoy tan orgulloso de ti que podría explotar como un globo lleno de felicidad. ¡Pum!",
    ],
  },
};

//get random dialog item in a content object
export function getRandomDialog(region: any) {
  const dialogs = content[region].dialog;
  const randomIndex = Math.floor((Math.random() * 1000) % dialogs.length);
  const randomMessage = dialogs[randomIndex];
  return { message: randomMessage, index: randomIndex };
}

export function getRandomRegion() {
  const regions = Object.keys(content);
  const randomIndex = Math.round(Math.random() * (regions.length - 1));
  const randomRegion = content[regions[randomIndex]] as IRegionContent;
  return randomRegion;
}

export default content;
