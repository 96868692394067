import { useDialog } from "context/DialogContext";
import React, { Suspense, useCallback, useMemo } from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

//Lazy load the rest of the steps

// const Step2 = React.lazy(() => import("./steps/step2"));
// const Step3 = React.lazy(() => import("./steps/step3"));
// const Step4 = React.lazy(() => import("./steps/step4"));
// const Step5 = React.lazy(() => import("./steps/step5"));
// const Step6 = React.lazy(() => import("./steps/step6"));
// const Step7 = React.lazy(() => import("./steps/step7"));
// const Step8 = React.lazy(() => import("./steps/step8"));
// const Step9 = React.lazy(() => import("./steps/step9"));
// const Step10 = React.lazy(() => import("./steps/step10"));
// const Step11 = React.lazy(() => import("./steps/step11"));
// const Step12 = React.lazy(() => import("./steps/step12"));
// const Step13 = React.lazy(() => import("./steps/step13"));

import OnboardingMusic1 from "components/audio/music/onboarding1";
import OnboardingMusic2 from "components/audio/music/onboarding2";
import EndingStep1 from "./steps/step1";
import EndingStep2 from "./steps/step2";
import EndingStep3 from "./steps/step3";

export default function Ending() {
	const navigate = useNavigate();
	const params = useParams();
	const { showDialog, resetDialog } = useDialog();

	const [step, setStep] = useState(0);

	// useEffect(() => {
	//   resetDialog();
	// }, [step, resetDialog]);

	useEffect(() => {
		setStep(params.step ? parseInt(params.step) : 1);
		if (!params.step) {
			navigate("/ending/1");
		}
	}, [navigate, params]);

	function getStep() {
		switch (step) {
			case 1:
				return <EndingStep2 />;
			case 2:
				return <EndingStep3 />;
			// case 3:
			//   return <EndingStep3 />;
			default:
				return null;
		}
	}

	const getMusic = useCallback(() => {
		if (step > 4 && step < 9) {
			return <OnboardingMusic1 />;
		} else if (step >= 9) {
			return <OnboardingMusic2 />;
		}
		return <></>;
	}, [step]);

	return (
		<div className="z-10 absolute w-full h-full md:overflow-hidden">
			{getStep()}
			{getMusic()}
		</div>
	);
}
