import { useAuth } from "context/AuthContext";
import "./styles.scss";
import { useDialog } from "context/DialogContext";
import { useEvent, useMount } from "react-use";
import { useNavigate } from "react-router-dom";
import AvatarCharacter from "components/avatar-character";
import RegionThumbnail from "components/region-thumbnail";

import { actionLog } from "services/actionlog.service";
import CharacterThumbnail from "components/character-thumbnail";
import { updateUserProfile } from "services/profile.service";
import { useAppStore } from "store";
import AudioEndingStep3 from "./audio";

export default function EndingStep3() {
  const { user, refreshUser } = useAppStore();
  const { showDialog } = useDialog();

  const navigate = useNavigate();

  useEvent("close-dialog", async () => {
    await updateUserProfile({
      ...user,
      extra: { ...user?.extra, onboarding_current: "finished" },
    });
    await refreshUser();
    navigate("/");
  });

  useMount(() => {
    showDialog(
      "Puedes volver a cada región y enfrentar nuevos retos para empoderarte con nuevos conocimientos, habilidades y motivaciones en diversas temáticas de tu interés.",
      null
    );
  });

  return (
    <>
      <AudioEndingStep3 />
      <div id="step11" className="relative top-0 left-0 w-full h-full">
        <div className="absolute top-[45%] left-[5%] w-[30vw] md:w-[20vw] animate-fly-fast">
          <div className="opacity-0 animate-fade-in">
            <CharacterThumbnail region="darklands" />
          </div>
        </div>

        <div className="absolute top-[15%] left-[20%] w-[30vw] md:w-[20vw]  animate-fly-slow">
          <div className="opacity-0 animate-fade-in delay-200">
            <CharacterThumbnail region="junkcity" />
          </div>
        </div>

        <div className="absolute top-[15%] left-[60%] w-[30vw] md:w-[20vw] animate-fly-fast delay-200">
          <div className="opacity-0 animate-fade-in delay-100">
            <CharacterThumbnail region="ocean-of-feelings" />
          </div>
        </div>

        <div className="absolute top-[45%] left-[65%] md:left-[75%] w-[30vw] md:w-[20vw]  animate-fly">
          <div className="opacity-0 animate-fade-in delay-50">
            <CharacterThumbnail region="prosperity-forest" />
          </div>
        </div>

        <div className="absolute bottom-[-15vw] left-16 md:left-32 w-[100vw] md:w-[48vw] right-0 mx-auto flex justify-center">
          <AvatarCharacter responsive />
        </div>
      </div>
    </>
  );
}
