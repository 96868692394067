import {
  createContext,
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMediaQuery } from "react-responsive";

interface ViewportContextData {}

interface ViewportProviderProps {
  children: ReactNode;
}

let resizeTimeout: any;

const ViewportContext = createContext({} as ViewportContextData);

function ViewportProvider({ children }: ViewportProviderProps) {
  const isTabletOrMobile = false; //useMediaQuery({ query: '(max-width: 1280px)' });

  const [scale, setScale] = useState({ x: 1, y: 1 });

  function onResize() {
    // const rootEl = document.getElementById("root");
    // const originalWidth = 1920;
    // const originalHeight = 1080;
    // const isVertical = window.innerWidth < window.innerHeight;
    // if (rootEl && isVertical) {
    //   rootEl.style.transform = ``;
    //   return;
    // }
    // let width = isVertical
    //   ? window.innerWidth
    //   : window.innerHeight * (originalWidth / originalHeight);
    // if (width > window.innerWidth) {
    //   width = window.innerWidth;
    // }
    // const height = width * (originalHeight / originalWidth);
    // const scaleY = height / originalHeight;
    // const scaleX = width / originalWidth;
    // setScale({ x: scaleX, y: scaleY });
    // if (rootEl) {
    //   rootEl.style.transform = `translate3d(-50%,-50%,0) scale(${scaleX}, ${scaleY})`;
    // }
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (resizeTimeout) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(onResize, 500);
    });

    onResize();
  }, []);

  const value = {};

  return (
    <ViewportContext.Provider value={value}>
      {children}
    </ViewportContext.Provider>
  );
}

function useViewport() {
  const context = useContext(ViewportContext);
  if (context === undefined) {
    throw new Error("useViewport must be used within a ViewportProvider");
  }
  return context;
}

export { ViewportProvider, useViewport };
