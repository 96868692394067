import { useWebsocketNotification } from "context/WebsocketNotificationContext";
import { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";

import "./styles.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { updateUserProfile } from "services/profile.service";
import { useAuth } from "context/AuthContext";
import { useAppStore } from "store";

export default function Notifications() {
  const { message } = useWebsocketNotification();
  const { user, refreshUser } = useAppStore();

  const [modalData, setModalData] = useState<any[]>([]);

  async function closeNotification() {
    const payload = [...modalData];
    payload.shift();
    setModalData(payload);

    // await updateUserProfile({
    //   _id: user?._id,
    //   extra: {
    //     ...user?.extra,
    //     lastMessage: modalData[0].created,
    //   },
    // });

    refreshUser();
  }

  // useEffect(() => {
  //   // if (Array.isArray(message)) {
  //   //   const data = [...modalData];
  //   //   message.forEach((item) => {
  //   //     if (data.find((i) => i._id === item._id)) return;
  //   //     data.push(item);
  //   //   });
  //   //   setModalData(data);
  //   // } else if (message) {
  //   //   if (modalData.find((i) => i._id === message._id)) return;
  //   //   setModalData([...modalData, message]);
  //   // }
  //   // console.log(message);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [message]);

  if (!modalData || modalData?.length < 1) return null;

  const item = modalData[0];

  return (
    <div className="modal-notification absolute top-10 left-[10%] border-2 border-solid border-electric-blue bg-oxford-blue rounded-lg p-7 w-[90vw] lg:w-[471px]">
      <button
        type="button"
        className="absolute -right-5 -top-5  rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
        onClick={() => closeNotification()}
      >
        <IoIosClose color="white" size={30} />
      </button>

      {item?.text && (
        <div
          className="relative w-full"
          dangerouslySetInnerHTML={{ __html: item?.text }}
        ></div>
      )}
    </div>
  );
}
