import { useDialog } from "context/DialogContext";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";

export default function VolumeControl() {
  const { isMuted, setIsMuted } = useDialog();

  function toggleMute() {
    setIsMuted(!isMuted);
  }

  return (
    <div className="fullscreen-button fixed top-0 right-12 z-40 bg-white">
      <button
        type="button"
        className="w-10 h-10 flex items-center justify-center bg-brand-red text-white"
        onClick={() => toggleMute()}
      >
        {isMuted ? <FaVolumeMute size={24} /> : <FaVolumeUp size={24} />}
      </button>
    </div>
  );
}
