import AvatarCharacter from "components/avatar-character";

import { useAuth } from "context/AuthContext";
import { useDialog } from "context/DialogContext";
import { useNavigate } from "react-router-dom";
import { useEvent, useMount } from "react-use";
import { useAppStore } from "store";
import AudioStep5 from "./audio";
import "./styles.scss";

export default function Step5() {
	const { user } = useAppStore();
	const { showDialog, closeDialog } = useDialog();
	const navigate = useNavigate();

	useEvent("close-dialog", () => {
		navigate("/onboarding/6");
	});

	const handleTimerOnFinished = () => {
		navigate("/onboarding/6");
	};

	useMount(() => {
		showDialog(
			"En otro multiverso, en un mundo no muy diferente al nuestro, existe tu alter ego, tu otro yo.",
			null,
		);
	});

	return (
		<>
			<AudioStep5 />
			<div id="step5" className="relative top-0 left-0 w-full h-full">
				<div className="absolute bottom-[20vw] md:bottom-[8vw] xl:bottom-[6vw] left-[10vw] md:left-0 w-[85vw] md:w-[28vw] right-0 mx-auto flex justify-center">
					<AvatarCharacter responsive />
				</div>
			</div>
		</>
	);
}
