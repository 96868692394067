import React, { useState, useEffect, PropsWithChildren } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const iconSexuality = require("assets/img/life-skills/sexuality.png");
const iconEmotions = require("assets/img/life-skills/emotions.png");
const iconRelationship = require("assets/img/life-skills/relationship.png");
const iconEducation = require("assets/img/life-skills/education.png");

const lifeSkillIcon: any = {
	sexuality: iconSexuality,
	emotion: iconEmotions,
	relationship: iconRelationship,
	education: iconEducation,
};

interface LifeSkillProgressProps {
	progress: number;
	lifeskill: string;
}

interface ProgressProviderProps {
	valueStart: number;
	valueEnd: number;
	children: (value: number) => JSX.Element;
}

function ProgressProvider({
	valueStart = 0,
	valueEnd = 1,
	children,
}: ProgressProviderProps) {
	const [value, setValue] = useState(valueStart);
	useEffect(() => {
		setValue(valueEnd);
	}, [valueEnd]);

	return children(value);
}

export default function LifeSkillProgress({
	progress,
	lifeskill,
}: LifeSkillProgressProps) {
	return (
		<ProgressProvider valueStart={0} valueEnd={progress}>
			{(value: number) => (
				<CircularProgressbarWithChildren value={value}>
					<div className="p-2">
						<img src={lifeSkillIcon[lifeskill]} alt="life skill" />
					</div>
				</CircularProgressbarWithChildren>
			)}
		</ProgressProvider>
	);
}
