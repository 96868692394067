import JSConfetti from "js-confetti";
import { useEffect } from "react";

export default function Decoration() {
  useEffect(() => {
    const jsConfetti = new JSConfetti();
    jsConfetti.addConfetti();
    return () => {
      jsConfetti.clearCanvas();
    };
  }, []);
  return null;
}
