/* eslint-disable react-hooks/exhaustive-deps */
import { useDialog } from "context/DialogContext";
import { useEffect } from "react";
import useSound from "use-sound";

const audioStep = require("assets/audio/onboarding/music_3.mp3");

export default function OnboardingMusic3() {
  const { isMuted } = useDialog();
  const [voiceOver, { stop, sound }] = useSound(audioStep, {
    id: "bgm",
    volume: 0.1,
  });

  useEffect(() => {
    if (!isMuted && sound) {
      voiceOver();
      sound.fade(0.2, 0, 10500);
    } else {
      stop();
    }
    return () => stop();
  }, [isMuted, voiceOver, sound]);

  return null;
}
