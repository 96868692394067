import { useAuth } from "context/AuthContext";
import { useModal } from "context/ModalContext";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import ReactModal from "react-modal";

import { updateUserProfile } from "services/profile.service";

import logo from "assets/img/logo.svg";
import { Button } from "flowbite-react";
import { actionLog } from "services/actionlog.service";
import { useAppStore } from "store";
import { LIFESKILL_CONTENT } from "utils/lifeskills";

export function LifeSkillSelectorModal() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { user, refreshUser, hasFinishedOnboarding } = useAppStore();

  const { openModal, closeModal, modalData } = useModal();

  const [loading, setLoading] = useState(false);

  ReactModal.setAppElement("#modal");

  function afterOpenModal() {
    //
  }

  function handleCloseModal() {
    setLoading(false);
    closeModal();
  }

  async function handleClick(lifeskill: string) {
    try {
      if (!user || loading) return;
      setLoading(true);

      if (!user.extra.lifeskill) {
        await actionLog("onboarding_finish");
      }

      await updateUserProfile({
        ...user,
        extra: {
          ...user.extra,
          lifeskill: lifeskill,
        },
      });

      await refreshUser();

      handleCloseModal();
    } catch (e) {}
  }

  useEffect(() => {
    if (openModal === "lifeskill") {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={false}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={handleCloseModal}
      contentLabel="Game Modal"
      portalClassName="relative z-30"
      overlayClassName="fixed z-100 top-0 left-0 right-0 bottom-0 bg-black bg-opacity-80 p-6 md:flex md:justify-center md:items-center"
      contentElement={() => (
        <>
          <div className="z-20 flex flex-col gap-4 px-6 py-6 md:px-10 justify-between items-center bg-white ring-2 ring-white shadow-2xl rounded-xl overflow-y-auto md:overflow-hidden relative w-full h-full m-auto max-w-[900px] md:max-h-[540px]">
            <button
              onClick={handleCloseModal}
              className="absolute z-40 bg-brand-red text-white p-2 rounded-full top-5 right-5"
            >
              <IoMdClose size={28} />
            </button>

            <img src={logo} alt="CRECER PARA SER" className="w-1/4" />
            <p className="text-xl text-center">
              Escoge el área que quieres mejorar:
            </p>
            <div className="grid md:grid-cols-4 gap-3 w-full">
              {LIFESKILL_CONTENT.map((item) => (
                <div
                  key={item.id}
                  onClick={() => handleClick(item.id)}
                  className={`relative rounded-lg ${item.color} overflow-hidden`}
                >
                  <img
                    src={item.background}
                    className="absolute w-full h-full pointer-events-none opacity-20"
                    alt={"background"}
                  />
                  <div className="relative z-20 w-full h-full text-center flex flex-col items-center text-white p-3 gap-3">
                    <div className="rounded-full bg-white aspect-square w-24 p-5">
                      <img src={item.image} alt={item.name} />
                    </div>
                    <h2 className="font-bold leading-none">{item.name}</h2>
                    <p className="leading-tight text-xs flex-1">
                      {item.description}
                    </p>
                    <Button
                      className={`bg-brand-blue border-2 border-white ${
                        loading ? "opacity-50" : ""
                      }`}
                    >
                      ¡VAMOS!
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    />
  );
}
