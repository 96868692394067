import { useDialog } from "context/DialogContext";
import { useCallback, useMemo } from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Step1 from "./steps/step1";
//Lazy load the rest of the steps

// const Step2 = React.lazy(() => import("./steps/step2"));
// const Step3 = React.lazy(() => import("./steps/step3"));
// const Step4 = React.lazy(() => import("./steps/step4"));
// const Step5 = React.lazy(() => import("./steps/step5"));
// const Step6 = React.lazy(() => import("./steps/step6"));
// const Step7 = React.lazy(() => import("./steps/step7"));
// const Step8 = React.lazy(() => import("./steps/step8"));
// const Step9 = React.lazy(() => import("./steps/step9"));
// const Step10 = React.lazy(() => import("./steps/step10"));
// const Step11 = React.lazy(() => import("./steps/step11"));
// const Step12 = React.lazy(() => import("./steps/step12"));
// const Step13 = React.lazy(() => import("./steps/step13"));

import OnboardingMusic1 from "components/audio/music/onboarding1";
import OnboardingMusic2 from "components/audio/music/onboarding2";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import Step5 from "./steps/step5";
import Step6 from "./steps/step6";
import Step7 from "./steps/step7";
import Step8 from "./steps/step8";
import Step9 from "./steps/step9";
import Step10 from "./steps/step10";
import Step11 from "./steps/step11";
import Step12 from "./steps/step12";
import Step13 from "./steps/step13";

export default function Onboarding() {
	const navigate = useNavigate();
	const params = useParams();
	const { showDialog, resetDialog } = useDialog();

	const [step, setStep] = useState(0);

	// useEffect(() => {
	//   resetDialog();
	// }, [step, resetDialog]);

	useEffect(() => {
		setStep(params.step ? parseInt(params.step) : 1);
		if (!params.step) {
			navigate("/onboarding/1");
		}
	}, [navigate, params]);

	function getStep() {
		switch (step) {
			case 1:
				return <Step1 />;
			case 2:
				return <Step2 />;
			case 3:
				return <Step3 />;
			case 4:
				return <Step4 />;
			case 5:
				return <Step5 />;
			case 6:
				return <Step6 />;
			case 7:
				return <Step10 />;
			case 8:
				return <Step12 />;
			case 9:
				return <Step13 />;
			case 10:
				return <Step10 />;
			case 11:
				return <Step11 />;
			case 12:
				return <Step12 />;
			case 13:
				return <Step13 />;
			default:
				return null;
		}
	}

	const getMusic = () => {
		if (step > 4 && step < 9) {
			return <OnboardingMusic1 />;
		} else if (step >= 9) {
			return <OnboardingMusic2 />;
		}
		return <></>;
	};

	return (
		<div className="z-10 absolute w-full h-full">
			{getStep()}
			{getMusic()}
		</div>
	);
}
