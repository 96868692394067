import { useAuth } from "context/AuthContext";
import "./styles.scss";
import { Button, TextInput } from "flowbite-react";
import { Link } from "react-router-dom";
import { useAppStore } from "store";

export default function Step2() {
  const { user } = useAppStore();

  return (
    <div
      id="step2"
      className="relative top-0 left-0 w-full h-full flex flex-col items-center justify-center"
    >
      <form>
        <div className="flex flex-col items-center bg-white rounded-xl p-3 space-y-5">
          <h1 className="leading-none header">
            ¿Cómo te gustaría que te llamaran?
          </h1>
          <TextInput defaultValue={user?.extra?.nombreUsuario} />
          <Link to="/onboarding/3">
            <Button>CONTINUAR</Button>
          </Link>
        </div>
      </form>
    </div>
  );
}
