import { api } from "./api";

export async function getAchievements(filters?: any[], limit = 100) {
  let payload: any[] = [];

  if (filters) {
    payload.concat(filters);
  }

  payload.push({
    $sort: {
      time: -1,
    },
  });

  if (limit) {
    payload.push({
      $limit: limit,
    });
  }

  const { data } = await api.post(`database/achievement/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getPointsHistory(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post("find/points", payload, {
      headers: { Range: "items=0-1000" },
    });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return {};
    } else {
      // map array to object where _id is the key
      const points = data.reduce((acc: any, item: any) => {
        acc[item._id] = item.total;
        return acc;
      }, {});
      return points;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}
