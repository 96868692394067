import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "store";
import { getStorageItem } from "utils/storage";

interface IPrivateRoute {
  permissions?: string[];
  roles?: string[];
  redirectTo?: string;
  children: ReactNode;
}

export function PrivateRoute({
  redirectTo = "/login",
  children,
}: IPrivateRoute) {
  // const { isAuthenticated, user } = useContext(AuthContext);
  const [allowed, setAllowed] = useState(false);
  const { restoreSession } = useAppStore();
  const navigate = useNavigate();

  useEffect(() => {
    restoreSession();
  }, [restoreSession]);

  useEffect(() => {
    const currentUser = getStorageItem("user");
    if (!currentUser) {
      setAllowed(false);
      navigate("/login");
    } else {
      setAllowed(true);
    }
  }, [navigate]);

  if (!allowed) {
    return <></>;
  }

  return <>{children}</>;
}
