import { useAuth } from "context/AuthContext";
import "./styles.scss";
import { Alert, Button, TextInput } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { globalStyles } from "styles";
import { IUserFindRequest } from "models";
import { useForm } from "react-hook-form";
import { useAppStore } from "store";
import { Info } from "@phosphor-icons/react";
import {
  IoMdInformationCircleOutline,
  IoMdNotificationsOutline,
} from "react-icons/io";
import { actionLog } from "services/actionlog.service";
import { addFriend } from "services/profile.service";
import { useMount } from "react-use";

export default function Step3() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<IUserFindRequest>();

  const navigate = useNavigate();
  const { user, findPlayer } = useAppStore();
  const [invite, setInvite] = useState(false);
  const [validUser, setValidUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState<any>(null);

  useMount(() => {
    setValidUser(false);
    setIsLoading(false);
  });

  const onSubmit = async ({ _id }: any) => {
    if (!user) return;

    setValidUser(false);

    if (user?.extra.nombreUsuario === _id) {
      setError("_id", { message: "No puedes invitarte a ti mismo" });
      return;
    }

    setIsLoading(true);

    try {
      const friend = await findPlayer(_id);
      if (friend) {
        setValidUser(true);
        await actionLog("accept_invite", { friend: friend._id });
        await addFriend(`${user?._id}`, friend._id);

        setTimeout(() => {
          navigate("/onboarding/4");
        }, 1000);
      }
      console.log("friend", friend);
    } catch (e) {
      setError("_id", { message: "No se encontró el usuario" });
      setIsLoading(false);
    }
  };

  function handleContinue() {
    navigate("/onboarding/4");
  }

  return (
    <div
      id="step3"
      className="relative top-0 left-0 w-full h-full flex flex-col items-center justify-center"
    >
      <div className="flex flex-col items-center bg-white rounded-xl p-10 space-y-8">
        <div className={`leading-none text-center ${globalStyles.header}`}>
          <p>¡Hola, {user?.extra.nombreUsuario}!</p>
          <hr className="my-3" />
          <p>¿Fuiste invitado por alguien?</p>
        </div>
        {!invite ? (
          <div className="flex flex-row items-center space-x-3 w-full">
            <Button
              className={globalStyles.button.success}
              fullSized
              onClick={() => setInvite(true)}
            >
              <span className="text-xl font-bold pb-1">SÍ</span>
            </Button>
            <Button
              className={globalStyles.button.danger}
              fullSized
              onClick={handleContinue}
            >
              <span className="text-xl font-bold pb-1">NO</span>
            </Button>
          </div>
        ) : null}
        {!!invite ? (
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <fieldset disabled={isLoading}>
              <div className="flex flex-col items-center space-y-3">
                <p className="text-center">
                  Escribe el nombre de usuario de quien te invitó.
                </p>

                <TextInput
                  type="text"
                  className="w-full"
                  placeholder="Nombre de Usuario"
                  sizing="lg"
                  {...register("_id")}
                />

                {errors && errors._id ? (
                  <Alert
                    color={"failure"}
                    icon={IoMdInformationCircleOutline}
                    className="w-full"
                  >
                    {errors._id?.message}
                  </Alert>
                ) : null}

                {!isLoading ? (
                  <Button
                    type="submit"
                    className={globalStyles.button.success}
                    fullSized
                  >
                    <span className="text-lg font-bold pb-1">
                      Validar Nombre de usuario
                    </span>
                  </Button>
                ) : null}

                {!!isLoading && !validUser ? (
                  <Button
                    type="button"
                    className={globalStyles.button.success}
                    fullSized
                  >
                    <span className="text-lg font-bold pb-1">Cargando...</span>
                  </Button>
                ) : null}

                {!!isLoading && validUser ? (
                  <Button type="button" className="bg-green-600" fullSized>
                    <span className="text-lg font-bold pb-1">
                      Usuario encontrado
                    </span>
                  </Button>
                ) : null}

                {!isLoading ? (
                  <Button
                    className={globalStyles.button.danger}
                    onClick={() => setInvite(false)}
                  >
                    <span className="text-lg font-bold pb-1">VOLVER</span>
                  </Button>
                ) : null}
              </div>
            </fieldset>
          </form>
        ) : null}
      </div>
    </div>
  );
}
